/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
export const DEFAULT_LOCALE = 'en'

export const GET_AGENCYTOKEN = 'Admin/App/GET_AGENCYTOKEN'
export const GET_AGENCYTOKEN_SUCCESS = 'Admin/App/GET_AGENCYTOKEN_SUCCESS'

export const GET_TOKEN = 'Admin/App/GET_TOKEN'
export const GET_TOKEN_SUCCESS = 'Admin/App/GET_TOKEN_SUCCESS'
export const GET_TOKEN_ERROR = 'Admin/App/GET_TOKEN_ERROR'

export const GET_TOKEN_AGENCY = 'Admin/App/GET_TOKEN_AGENCY'
export const GET_TOKEN_AGENCY_SUCCESS = 'Admin/App/GET_TOKEN_AGENCY_SUCCESS'
export const GET_TOKEN_AGENCY_ERROR = 'Admin/App/GET_TOKEN_AGENCY_ERROR'

export const CREATE_PLAYER = 'Admin/App/CREATE_PLAYER'
export const CREATE_PLAYER_SUCCESS = 'Admin/App/CREATE_PLAYER_SUCCESS'
export const CREATE_PLAYER_ERROR = 'Admin/App/CREATE_PLAYER_ERROR'

export const CREATE_EVAL_FOR_PLAYER = 'Admin/App/CREATE_EVAL_FOR_PLAYER'
export const CREATE_EVAL_FOR_PLAYER_SUCCESS = 'Admin/App/CREATE_EVAL_FOR_PLAYER_SUCCESS'
export const CREATE_EVAL_FOR_PLAYER_ERROR = 'Admin/App/CREATE_EVAL_FOR_PLAYER_ERROR'

export const GET_PLAYERS = 'Admin/App/GET_PLAYERS'
export const GET_PLAYERS_SUCCESS = 'Admin/App/GET_PLAYERS_SUCCESS'
export const GET_PLAYERS_ERROR = 'Admin/App/GET_PLAYERS_ERROR'

export const GET_AGENCIES = 'Admin/App/GET_AGENCIES'
export const GET_AGENCIES_SUCCESS = 'Admin/App/GET_AGENCIES_SUCCESS'
export const GET_AGENCIES_ERROR = 'Admin/App/GET_AGENCIES_ERROR'

export const GET_SIMU_PLAYERS = 'Admin/App/GET_SIMU_PLAYERS'
export const GET_SIMU_PLAYERS_SUCCESS = 'Admin/App/GET_SIMU_PLAYERS_SUCCESS'
export const GET_SIMU_PLAYERS_ERROR = 'Admin/App/GET_SIMU_PLAYERS_ERROR'

export const GET_DASHBOARD = 'Admin/App/GET_DASHBOARD'
export const GET_DASHBOARD_SUCCESS = 'Admin/App/GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_ERROR = 'Admin/App/GET_DASHBOARD_ERROR'

export const GET_ARCHIVE_PLAYERS = 'Admin/App/GET_ARCHIVE_PLAYERS'
export const GET_ARCHIVE_PLAYERS_SUCCESS = 'Admin/App/GET_ARCHIVE_PLAYERS_SUCCESS'
export const GET_ARCHIVE_PLAYERS_ERROR = 'Admin/App/GET_ARCHIVE_PLAYERS_ERROR'

export const CREATE_ARCHIVE_PLAYER = 'Admin/App/CREATE_ARCHIVE_PLAYER'
export const CREATE_ARCHIVE_PLAYER_SUCCESS = 'Admin/App/CREATE_ARCHIVE_PLAYER_SUCCESS'
export const CREATE_ARCHIVE_PLAYER_ERROR = 'Admin/App/CREATE_ARCHIVE_PLAYER_ERROR'

export const REVERT_ARCHIVE_PLAYER = 'Admin/App/REVERT_ARCHIVE_PLAYER'
export const REVERT_ARCHIVE_PLAYER_SUCCESS = 'Admin/App/REVERT_ARCHIVE_PLAYER_SUCCESS'
export const REVERT_ARCHIVE_PLAYER_ERROR = 'Admin/App/REVERT_ARCHIVE_PLAYER_ERROR'


export const DELETE_PLAYER = 'Admin/App/DELETE_PLAYER'
export const DELETE_PLAYER_SUCCESS = 'Admin/App/DELETE_PLAYER_SUCCESS'
export const DELETE_PLAYER_ERROR = 'Admin/App/DELETE_PLAYER_ERROR'

export const DELETE_SIMU_PLAYER = 'Admin/App/DELETE_SIMU_PLAYER'
export const DELETE_SIMU_PLAYER_SUCCESS = 'Admin/App/DELETE_SIMU_PLAYER_SUCCESS'
export const DELETE_SIMU_PLAYER_ERROR = 'Admin/App/DELETE_SIMU_PLAYER_ERROR'

export const DELETE_ARCHIVE = 'Admin/App/DELETE_ARCHIVE'
export const DELETE_ARCHIVE_SUCCESS = 'Admin/App/DELETE_ARCHIVE_SUCCESS'
export const DELETE_ARCHIVE_ERROR = 'Admin/App/DELETE_ARCHIVE_ERROR'

export const DELETE_AGENCY = 'Admin/App/DELETE_AGENCY'
export const DELETE_AGENCY_SUCCESS = 'Admin/App/DELETE_AGENCY_SUCCESS'
export const DELETE_AGENCY_ERROR = 'Admin/App/DELETE_AGENCY_ERROR'

export const AGENCY_LOGIN = 'Admin/App/AGENCY_LOGIN'
export const AGENCY_LOGIN_SUCCESS = 'Admin/App/AGENCY_LOGIN_SUCCESS'
export const AGENCY_LOGIN_ERROR = 'Admin/App/AGENCY_LOGIN_ERROR'

export const AGENCY_CHECK_TOKEN = 'Admin/App/AGENCY_CHECK_TOKEN'
export const AGENCY_CHECK_TOKEN_SUCCESS = 'Admin/App/AGENCY_CHECK_TOKEN_SUCCESS'
export const AGENCY_CHECK_TOKEN_ERROR = 'Admin/App/AGENCY_CHECK_TOKEN_ERROR'

export const AGENCY_LOGIN_RESET = 'Admin/App/AGENCY_LOGIN_RESET'

export const CHANGE_PASSWORD = 'Admin/App/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'Admin/App/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'Admin/App/CHANGE_PASSWORD_ERROR'

export const SIMU_PLAYER_EDIT = 'Admin/App/SIMU_PLAYER_EDIT'
export const SIMU_PLAYER_EDIT_SUCCESS = 'Admin/App/SIMU_PLAYER_EDIT_SUCCESS'
export const SIMU_PLAYER_EDIT_ERROR = 'Admin/App/SIMU_PLAYER_EDIT_ERROR'

export const PLAYER_EDIT = 'Admin/App/PLAYER_EDIT'
export const PLAYER_EDIT_SUCCESS = 'Admin/App/PLAYER_EDIT_SUCCESS'
export const PLAYER_EDIT_ERROR = 'Admin/App/PLAYER_EDIT_ERROR'

export const RESET_PASSWORD = 'Admin/App/RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'Admin/App/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'Admin/App/RESET_PASSWORD_ERROR'

export const CHECK_TEMPORARY_TOKEN = 'Admin/App/CHECK_TEMPORARY_TOKEN'
export const CHECK_TEMPORARY_TOKEN_SUCCESS = 'Admin/App/CHECK_TEMPORARY_TOKEN_SUCCESS'
export const CHECK_TEMPORARY_TOKEN_ERROR = 'Admin/App/CHECK_TEMPORARY_TOKEN_ERROR'

export const CHANGE_AGENCY_PASSWORD = 'Admin/App/CHANGE_AGENCY_PASSWORD'
export const CHANGE_AGENCY_PASSWORD_SUCCESS = 'Admin/App/CHANGE_AGENCY_PASSWORD_SUCCESS'
export const CHANGE_AGENCY_PASSWORD_ERROR = 'Admin/App/CHANGE_AGENCY_PASSWORD_ERROR'

export const REFRESH_PLAYERS = 'Admin/App/REFRESH_PLAYERS'
export const REFRESH_PLAYERS_SUCCESS = 'Admin/App/REFRESH_PLAYERS_SUCCESS'
export const REFRESH_PLAYERS_ERROR = 'Admin/App/REFRESH_PLAYERS_ERROR'

export const REFRESH_SIMU_PLAYERS = 'Admin/App/REFRESH_SIMU_PLAYERS'
export const REFRESH_SIMU_PLAYERS_SUCCESS = 'Admin/App/REFRESH_SIMU_PLAYERS_SUCCESS'
export const REFRESH_SIMU_PLAYERS_ERROR = 'Admin/App/REFRESH_SIMU_PLAYERS_ERROR'

export const GET_FILTERED_AGENCIES = 'Admin/App/GET_FILTERED_AGENCIES'
export const GET_FILTERED_AGENCIES_SUCCESS = 'Admin/App/GET_FILTERED_AGENCIES_SUCCESS'
export const GET_FILTERED_AGENCIES_ERROR = 'Admin/App/GET_FILTERED_AGENCIES_ERROR'

export const REFRESH_AGENCIES = 'Admin/App/REFRESH_AGENCIES'
export const REFRESH_AGENCIES_SUCCESS = 'Admin/App/REFRESH_AGENCIES_SUCCESS'
export const REFRESH_AGENCIES_ERROR = 'Admin/App/REFRESH_AGENCIES_ERROR'

export const GET_TOKEN_SIMU = 'Admin/App/GET_TOKEN_SIMU'
export const GET_TOKEN_SIMU_SUCCESS = 'Admin/App/GET_TOKEN_SIMU_SUCCESS'
export const GET_TOKEN_SIMU_ERROR = 'Admin/App/GET_TOKEN_SIMU_ERROR'

export const SET_SCALE_EVAL = 'Admin/App/SET_SCALE_EVAL'
export const SET_SCALE_EVAL_SUCCESS = 'Admin/App/SET_SCALE_EVAL_SUCCESS'
export const SET_SCALE_EVAL_ERROR = 'Admin/App/SET_SCALE_EVAL_ERROR'

export const VERIF_EMAIL = 'Admin/App/VERIF_EMAIL'
export const VERIF_EMAIL_SUCCESS = 'Admin/App/VERIF_EMAIL_SUCCESS'
export const VERIF_EMAIL_ERROR = 'Admin/App/VERIF_EMAIL_ERROR'

export const LOAD_JOURNEYS = 'quiz/App/LOAD_JOURNEYS'
export const LOAD_JOURNEYS_SUCCESS = 'quiz/App/LOAD_JOURNEYS_SUCCESS'
export const LOAD_JOURNEYS_ERROR = 'quiz/App/LOAD_JOURNEYS_ERROR'

export const AGENCY_EDIT = 'Admin/App/AGENCY_EDIT'
export const AGENCY_EDIT_SUCCESS = 'Admin/App/AGENCY_EDIT_SUCCESS'
export const AGENCY_EDIT_ERROR = 'Admin/App/AGENCY_EDIT_ERROR'

export const HIDE_MODAL = 'Admin/App/HIDE_MODAL'

export const RESET = 'Admin/App/RESET'
