/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import globalReducer from './app/containers/App/reducer'

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
// const routeInitialState = fromJS({
//   location: null
// })

/**
 * Merge route into the global application state
 */
// export function routeReducer (state = routeInitialState, action) {
//   switch (action.type) {
//     /* istanbul ignore next */
//     case LOCATION_CHANGE:
//       return state.merge({
//         location: action.payload
//       })
//     default:
//       return state
//   }
// }

/**
 * Creates the main reducer with the dynamically injected ones
 */
const createRootReducer = (history, injectedReducers) => combineReducers({
    router: connectRouter(history),
    global: globalReducer,
    ...injectedReducers
})

export default createRootReducer
