import styled from 'styled-components'
import UiBoy from '../../images/boy.svg'
import layers from '../../images/layers.svg'
import { Link } from 'react-router-dom'

export const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

export const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 84.88%;
  background-color: white;
  text-align: center;
`

export const Selector = styled.select`
  border-radius: 10px;
  background-color: white;
  border: 1px solid #B3ABBC;
  outline: none;
  height: 2.37rem;
  width: 35%;
  padding-left: 2.25rem;
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
  background-image: url(${UiBoy});
  background-size: 1.1rem;
  background-position: top 50% left 1rem;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 8px;
`

export const SelectorJourney = styled.select`
border-radius: 10px;
background-color: white;
border: 1px solid #B3ABBC;
outline: none;
height: 2.37rem;
width: 35%;
padding-left: 2.25rem;
&:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
background-image: url(${layers});
background-size: 1.1rem;
background-position: top 50% left 1rem;
background-repeat: no-repeat;
-webkit-appearance: none;
-moz-appearance: none;
text-indent: 8px;
`

export const Opt = styled.option`
  padding-left: 3rem;
  color: black;
`

export const SelectorContainer = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  position:relative;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  style: normal;
  width: 35%;
  margin: auto;
  margin-top: 1.3rem;
  border-bottom: 1px solid #979797;
  cursor:pointer;
`

export const Info = styled(Link)`
  height:41px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  flex: 1;
`

export const Parcours = styled.div`
  cursor: pointer;
  text-decoration: none;
  color: black;
  flex: 1;
`

export const Stats = styled.div`
  cursor: pointer;
  text-decoration: none;
  color: #166dd8;

  & {
    border-bottom: 3px solid #166dd8;
  }
  flex: 1;
`

export const TotalStatsContainer = styled.div`
  height: 6.19rem;
  border-radius: 10px;
  font-familly: Poppins;
  color: white;
  font-size: 4.6rem;
  font-weight: 700;
  text-align: center;
  align-items: center;
  margin: auto;
  line-height: 7.5rem;
  width: 35%;
  background: linear-gradient(90deg, #1aa0ff 0%, #166dd8 95.42%);
`

export const TotalStats = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TotalStatsPercent = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  padding-top: 3.2%; 
  padding-left: 4px;
`

export const AllStats = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  margin: auto;
  margin-top: 1rem;
  width: 35%;
`

export const ProgressionBar = styled.div`
  margin-left: 0.4rem;
  width: 90%;
  height: 1rem;
  background: #eff0f6;
  border-radius: 0.3rem;
  margin-left: 1.4rem;
  margin-bottom: 1.5rem;
`

export const ProgressionBarIn = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 0.3rem;
  background: linear-gradient(90deg, #1aa0ff 0%, #166dd8 95.42%);
`

// Printer components
export const PrintContainer = styled.div`
  position: relative;
  display: flex;
  height:3rem;
  width:30%;
  margin:auto;
`

export const ArrowStudent = styled.img`
  position: absolute; 
  width: 12px;
  z-index: 10;
  top: 78%;
  left: 65.5%;
`
export const ArrowJourney = styled.img`
  position: absolute; 
  width: 12px;
  z-index: 10;
  top: 71%;
  left: 65.5%;
`
export const Print = styled.button`
position:absolute;
display: flex;
justify-content: space-around; 
align-items: center;  
width:13.1rem;
height: 100%;
margin-top: 1rem;
margin-bottom:5rem;
right: -40px;
font-family: Poppins;
font-size: 1rem;
font-weight: 500;
font-style:normal;
line-height:1rem;
box-shadow:0px 2px 7px rgba(0,0,0,0.14);
border:1px solid #979797;
border-radius: 0.5rem;
cursor:pointer;
box-shadow:0px 2px 7px rgba(0,0,0,0.14);
`
