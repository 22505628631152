import React from 'react'
import '../../fonts/fonts.css'

export const PopUpdate = () => {
  // const [show, setShow] = useState(true)

  // const hideNews = () => {
  //   setShow(false)
  // }

  return (
    <div>
      {/* <Header style={{ display: show ? 'flex' : 'none' }}> // TODO: What for ???
        <Exit onClick={hideNews()} src={Close} />
        <Date>26-02-2021</Date>
        <Title>Nouvelle version 1.02.1 du SIMUTGD </Title>
        {config.features.simu ? <Button to='/News' text='Voir les actus' /> : null}
      </Header> */}
    </div>
  )
}

export default PopUpdate
