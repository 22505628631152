import styled from 'styled-components'
import React from 'react'

// # # # # # # # # # # # # # TITLES & SPANS # # # # # # # # # # # # # # # # # # # #

export const Title0 = styled.div`
  font-size: 1.2rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 0;
  margin-bottom: ${props => props.marginBottom || '10px'};
  letter-spacing: 0.5px;
`

export const Title1 = styled.span`
  font-size: 0.6rem;
  font-family: Poppins;
  font-weight: 400;
  color: #828282;
  margin-bottom: ${props => props.marginBottom || '0.4rem'};
  margin-left: ${props => props.marginLeft || '0.7rem'};
`
export const Title2 = styled.span`
  font-size: 0.5rem;
  font-family: Poppins;
  color: #828282;
  margin-bottom: ${props => props.marginBottom || '0px'};
`

export const Spacer = styled.div`
  height: ${props => props.height};
`

export const SpacerHorizontal = styled.div`
  width: ${props => props.width};
`

// # # # # # # # # # # # # # YELLOW BUTTON # # # # # # # # # # # # # # # # # # # #

/*
  props :
  <YellowButton
    text='editer'
    imgSrc={UiUploadImg}
    callback={() => {this.startEdit()}}
    state='active'
  />
*/

const ButtonYellow = styled.button`
  cursor: ${props => props.state === 'inactive' ? 'normal' : 'pointer'};
  background-color:${props => props.state === 'inactive' ? '#EFF0F6' : '#FFCA46'};
  color:${props => props.state === 'inactive' ? '#868383' : 'black'};
  display:flex;
  width:${props => props.width || 'none'};
  align-items:center;
  border-radius:0.5rem;
  padding:0 0.9rem 0 0.9rem;
  height:2.5rem;
  transition:all 0.2s ease;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.14);
  outline:none;
  &:hover {
    background-color: ${props => props.state === 'inactive' ? '#EFF0F6' : '#FFE299'};;
    transform: ${props => props.state === 'inactive' ? 'none' : 'scale(1.02)'};;
  }
`

const ImgButtonYellow = styled.img`
  margin-right:0.5rem
`

const TextYellowButton = styled.div`
  font-family: Poppins;
  font-size:0.7rem;
  font-weight:500
`

export const YellowButton = (props) => {
  return (
    <ButtonYellow
      onClick={() => {
        if (props.state !== 'inactive') { props.callback() }
      }}
      state={props.state}
      width={props.width}
    >
      {props.imgSrc && <ImgButtonYellow src={props.imgSrc} />}
      <TextYellowButton>{props.text}</TextYellowButton>
      {props.children}
    </ButtonYellow>

  )
}

// # # # # # # # # # # # # # TRANSPARENT BUTTON # # # # # # # # # # # # # # # # # # # #
// a few parameters different from YellowButton

const ButtonHollow = styled.button`
  cursor: pointer;
  color:#868383;
  display:flex;
  align-items:center;
  border-radius:0.5rem;
  padding:0 0.9rem 0 0.9rem;
  height:2.5rem;
  outline:none;
  border:solid 1px #868383
`

export const HollowButton = (props) => {
  return (
    <ButtonHollow
      onClick={() => {
        props.callback()
      }}
    >
      <TextYellowButton>{props.text}</TextYellowButton>
    </ButtonHollow>
  )
}
