import React from 'react'
import styled from 'styled-components'

const Icon = styled.img`
height: 1rem;
width:1.25rem;
margin-left: 1.5rem;
`

const ButtonArrowRight = (props) => {
  return (
    <div>{props.icon ? <Icon src={props.icon} /> : null}
    </div>
  )
}

export default ButtonArrowRight
