import React from 'react'
import styled from 'styled-components'

const Icon = styled.img`
  width:1rem;
  height:1rem;
 
`
const Container = styled.div`
  width:1rem;
  height:1rem;
 
`
const InputPasswordIcon = (props) => {
  return (
    <Container>
      {props.icon ? <Icon src={props.icon} /> : null}
    </Container>
  )
}

export default InputPasswordIcon
