import { withRouter, useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import injectSaga from '../../utils/injectSaga'
import ProgressBar from '../../components/ProgressBar'
import Circle from '../../components/StatsCircle'
import arrow from '../../images/arrowML.svg'
import { refreshSimuPlayersSaga } from '../RefreshPlayers/saga'
import config from '../../config/configurator'
import statsLogo from '../../images/Stats.svg'
import {
  StatsBox,
  Percent,
  BoxTitle,
  Bg,
  Stats,
  StatsRow,
  StatsRowKM,
  SkillTitle,
  SkillsContainer,
  SkillsContainer2,
  MainLayout,
  MainContainer,
  Info,
  InfoContainer,
  StatsTime,
  StatsTitle,
  Score,
  SelectorStatsContainer,
  StatsSelector,
  DashboardContainer
} from './styledComponents'
import Dashboard from '../../components/Dashboard'
import IOButtonSendData from '../../components/IOButton_SendData'

const StatsSimu = () => {
  const location = useLocation()
  const axios = require('axios')
  const history = useHistory()

  const simuPlayers = useSelector((state) => state.toJS().global.simuPlayers)
  const accessToken = useSelector((state) => state.toJS().global.agencytoken)
  const remoteToken = useSelector((state) => state.toJS().global.remotetoken)
  const entity = useSelector((state) => state.toJS().global.entity || 'TGD')

  const id = location.query
    ? location.query.id
    : location.pathname.split('/')[3]

  const [colors, setColors] = useState([' ', ' ', ' '])
  const [borderBottom, setBorderBottom] = useState([' ', ' ', ' '])
  const [circleSize, setCircleSize] = useState(80)
  const [stats, setStats] = useState({})
  const [skills, setSkills] = useState({})

  const player = simuPlayers
  ? simuPlayers.find((player) => player.id === id || player.studentId === id)
  : ''


  useEffect(() => {
    console.log('LOCATION', location.pathname.split('/')[3])
    window.addEventListener('resize', ResizeCircle)
    
    if (simuPlayers) {
      const id = location.pathname.split('/')[3]
      console.log('ID', id)
      const player = simuPlayers.find((player) =>   player._id === id || player.studentId === id || player.id === id)
      console.log('FOUND player', player, stats)
      if (player /* && !stats */ /* && !isB2VM */) {
        if (config.title === 'ECF') {
          getAllProgression(player)
        } else {
          getStatsREMC(player)
          getSkillsREMC(player)
        }
      }
    }
    // eslint-disable-next-line
  }, [])

  const ResizeCircle = () => {
    setCircleSize(window.innerWidth / 17)
  }

  const getStatsREMC = async (player) => {
    console.log('GET STATS', player)
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/api/playerTGDs/${player.foreignKey}/getstatsremc?access_token=${remoteToken}`
      )
      .then((res) => {
        console.log('res data', res.data)
        setStats(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSkillsREMC = async (player) => {
    console.log('GET SKILLS', player)
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/api/playerTGDs/${player.foreignKey}/saveremc?access_token=${remoteToken}`
      )
      .then((res) => {
        setSkills(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getAllProgression = async (player) => {
    console.log(`${process.env.REACT_APP_TGDAPI_SERVER}/progressions?studentId=${player.studentId}`)
    const headers = {
      Authorization: `Bearer ${accessToken}`
    }
    await axios
    .get(
      `${process.env.REACT_APP_TGDAPI_SERVER}/progressions?studentId=${player.studentId}`,
        {headers}
    )
    .then((res) => {
      progressionsToStatsFormatter(res.data)
      console.log('progressions response data:',res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const progressionsToStatsFormatter = (progressions) => {

    let formattedSkills = {
      DistancePlay: 0,
      PlayTime: 0,
      Completion: {
        Counts: [0,0,0,0],
        Percents: [0,0,0,0],
        Count: 0,
        CountTotal: 55
      }
    }

    let formattedStats = {
      position_global: 0,
      allure_global: 0,
      retroviseur_global: 0,
      regard_global: 0,
      communication_global: 0,
      ecoStat: 0
    }

    let ratioStats = {
      position_global: 0,
      allure_global: 0,
      retroviseur_global: 0,
      regard_global: 0,
      communication_global: 0,
      ecoStat: 0
    }

    progressions.forEach((progression) => {
      // Skills

      const regex = /seq(\d)/
      const regexres = regex.exec(progression.sequenceId)

      if (regexres) {
          if (regexres[1] === '1') {
            formattedSkills.Completion.Counts[0]++
            formattedSkills.Completion.Percents[0] = formattedSkills.Completion.Counts[0] / 13 * 100
          } else if (regexres[1] === '2') {
            formattedSkills.Completion.Counts[1]++
            formattedSkills.Completion.Percents[1] = formattedSkills.Completion.Counts[1] / 16 * 100
          } else if (regexres[1] === '3') {
            formattedSkills.Completion.Counts[2]++
            formattedSkills.Completion.Percents[2] = formattedSkills.Completion.Counts[2] / 21 * 100
          } else if (regexres[1] === '4') {
            formattedSkills.Completion.Counts[3]++
            formattedSkills.Completion.Percents[3] = formattedSkills.Completion.Counts[3] / 5 * 100
          }
          formattedSkills.Completion.Count++
  
        formattedSkills.DistancePlay += progression.sessionDistance
        formattedSkills.PlayTime += progression.sessionTime
  
        // Stats
  
        if (progression.sectionScores) {
          progression.sectionScores.forEach((section) => {
            if (section.stats) {
              console.log(section.stats)
              if (section.stats.position > 0) {
                formattedStats.position_global += section.stats.position
                ratioStats.position_global++
                formattedStats.position_global = ratioStats.position_global !== 0 ? formattedStats.position_global / ratioStats.position_global : 0
              }
              if (section.stats.pace > 0) {
                formattedStats.allure_global += section.stats.pace
                ratioStats.allure_global++
                formattedStats.allure_global = ratioStats.allure_global !== 0 ? formattedStats.allure_global / ratioStats.allure_global : 0
              }
              if (section.stats.mirror > 0) {
                formattedStats.retroviseur_global += section.stats.mirror
                ratioStats.retroviseur_global++
                formattedStats.retroviseur_global = ratioStats.retroviseur_global !== 0 ? formattedStats.retroviseur_global / ratioStats.retroviseur_global : 0
              }
              if (section.stats.sight > 0) {
                formattedStats.regard_global += section.stats.sight
                ratioStats.regard_global++
                formattedStats.regard_global = ratioStats.regard_global !== 0 ? formattedStats.regard_global / ratioStats.regard_global : 0
              }
              if (section.stats.communication > 0) {
                formattedStats.communication_global += section.stats.communication
                ratioStats.communication_global++
                formattedStats.communication_global = ratioStats.communication_global !== 0 ? formattedStats.communication_global / ratioStats.communication_global : 0
              }
            }
          })
        }
      }
    })
    setStats(formattedStats)
    setSkills(formattedSkills)
  }

  const handleChangeStats = (event) => {
    if (event.value === 'TGD2') {
      history.push(`/simuplayers/statsb2vm/${id}`)
    }
  }

  function displayStatsSelector () {
    const statsValues = [
      {value: 'TGD1', label: 'TGD1'},
      {value: 'TGD2', label: 'TGD2'}
    ]
    return (
    <StatsSelector
      classNamePrefix='react-select'
      multiple={false}
      isSearchable={false}
      defaultValue={statsValues[0]}
      onChange={(e) => {handleChangeStats(e)}}
      options={statsValues}>
    </StatsSelector>)
  }

  const getTime = (time) => {
    if (time) {
      const h = parseInt(time / 60 / 60)
      const m = parseInt((time / 60) % 60)

      return h + 'h ' + m + 'm'
    }
    return 'Never played'
  }

  /* const update = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_API}/api/playerTGDs/${id}/getstatsremc?access_token=${accessToken}`)
    const data = await response.json()
    setStats(data)
  }

  const handleChange = (e) => {
    history.push(`/simuplayers/stats/${e.target.value}`)
    update(e.target.value)
  }
 */

  // const displaySelector = () => {
  //   if (simuPlayers) {
  //     return (
  //       <Selector style={{ marginTop: '4rem', outline: 'none' }} onChange={(e) => { handleChange(e) }} value={id}> {/* value={[player.get('id'), player.get('firstname'), player.get('lastname'), player.get('email'), player.get('agencyId')] */}
  //         {simuPlayers.map((pl, i = 0) => {
  //           if (pl.foreignKey === 'fkeditor') {
  //             return null
  //           }
  //           return <Opt key={i} value={pl.id}>{pl.firstname + ' ' + pl.lastname}</Opt>
  //         })}
  //       </Selector>
  //     )
  //   }
  // }

  // switch color in the infoContainer to know where we are
  const onClickColor = (e) => {
    setColors([
      e === 0 ? '#166DD8' : 'black',
      e === 1 ? '#166DD8' : 'black',
      e === 2 ? '#166DD8' : 'black',
    ])
    setBorderBottom([
      e === 0 ? '3px solid #166DD8' : 'black',
      e === 1 ? '3px solid #166DD8' : 'black',
      e === 2 ? '3px solid #166DD8' : 'black',
    ])
  }

  const getRatios = () => {
    if (stats != null) {
      const ecoStat =
        (stats.ecoAccelerator_global +
          stats.ecoStableSpeed_global +
          stats.ecoBrakes_global) /
        3
      return (
        // here we are going in the state to find the stats and then in the stats we found which stats we need in the json
        <StatsBox>
          <BoxTitle>
            Position
            <Circle size={circleSize} ratio={stats.position_global} />
            <Percent>{Math.round(stats.position_global * 100) || 0}</Percent>
          </BoxTitle>
          <BoxTitle>
            Allure
            <Circle size={circleSize} ratio={stats.allure_global} />
            <Percent>{Math.round(stats.allure_global * 100) || 0}</Percent>
          </BoxTitle>
          <BoxTitle>
            Rétroviseurs
            <Circle size={circleSize} ratio={stats.retroviseur_global} />
            <Percent>{Math.round(stats.retroviseur_global * 100) || 0}</Percent>
          </BoxTitle>
          {/* <StatsBox2> */}
          <BoxTitle>
            Regard
            <Circle size={circleSize} ratio={stats.regard_global} />
            <Percent>{Math.round(stats.regard_global * 100) || 0}</Percent>
          </BoxTitle>
          <BoxTitle>
            Communication
            <Circle size={circleSize} ratio={stats.communication_global} />
            <Percent>
              {Math.round(stats.communication_global * 100) || 0}
            </Percent>
          </BoxTitle>
          <BoxTitle>
            EcoConduite
            <Circle size={circleSize} ratio={ecoStat} />
            <Percent>{Math.round(ecoStat * 100) || 0}</Percent>
          </BoxTitle>
          {/* </StatsBox2> */}
        </StatsBox>
      )
    }
  }

  if (skills) {
    return (
      <Bg>
        <SideBar />
        <MainLayout>
          <MainTitle text='Statistiques' icon={statsLogo} />
          <MainContainer>
            {/* <SelectorContainer>
              <ArrowStudent src={ArrowDown} />
              {displaySelector()}
            </SelectorContainer> */}
            <DashboardContainer>
              <Dashboard height={'48px'} type='infos' data={{firstName: player.firstname, lastName: player.lastname}}/>
              {entity === "IO" ?
                <div style={{position: 'absolute', right: '-60px', bottom: '0px'}}>
                  <IOButtonSendData
                    id={id}
                    accessToken={accessToken}
                  />
                </div>
              : null}
            </DashboardContainer>
            <InfoContainer>
            <Info
                to={`/simuplayers/edit/${id}`}
                style={{
                  color: colors[2],
                  borderBottom: borderBottom[2],
                }}
                onClick={(e) => onClickColor(2)}
              >
              Informations
            </Info>
            <Stats
                style={{
                  color: colors[0],
                  borderBottom: borderBottom[0],
                }}
                onClick={(e) => onClickColor(0)}
              >
                Statistiques
              </Stats>
              {/* <Parcours
                style={{
                  color: colors[1],
                  borderBottom: borderBottom[1]
                }}
                onClick={(e) => onClickColor(1)}
              >
                Parcours
              </Parcours>
              */}
            </InfoContainer>
            <SelectorStatsContainer>
              {displayStatsSelector()}
            </SelectorStatsContainer>
            <StatsRow>
              <StatsTitle src={arrow}>Trajets validés</StatsTitle>
              <StatsTime>
                {skills.Completion
                  ? `${skills.Completion.Count || 0} / ${
                      skills.Completion.CountTotal || 0
                    }`
                  : '0 / 55'}
              </StatsTime>
            </StatsRow>
            <SkillsContainer>
              <SkillTitle>
                <Score>
                  {skills.Completion ? skills.Completion.Counts[0] : 0}/13
                </Score>
                Compétence 1
                <ProgressBar
                  percentage={
                    skills.Completion ? skills.Completion.Percents[0] : 0
                  }
                />
              </SkillTitle>
              <SkillTitle>
                <Score>
                  {skills.Completion ? skills.Completion.Counts[1] || 0 : 0}/16
                </Score>
                Compétence 2
                <ProgressBar
                  percentage={
                    skills.Completion
                      ? skills.Completion.Percents[1]
                        ? skills.Completion.Percents[1]
                        : 0
                      : 0
                  }
                />
              </SkillTitle>
              <SkillTitle>
                <Score>
                  {skills.Completion ? skills.Completion.Counts[2] || 0 : 0}/21
                </Score>
                Compétence 3
                <ProgressBar
                  percentage={
                    skills.Completion
                      ? skills.Completion.Percents[2]
                        ? skills.Completion.Percents[2]
                        : 0
                      : 0
                  }
                />
              </SkillTitle>
              <SkillTitle>
                <Score>
                  {skills.Completion ? skills.Completion.Counts[3] || 0 : 0}/5
                </Score>
                Compétence 4
                <ProgressBar
                  percentage={
                    skills.Completion
                      ? skills.Completion.Percents[3]
                        ? skills.Completion.Percents[3]
                        : 0
                      : 0
                  }
                />
              </SkillTitle>
            </SkillsContainer>
            <StatsRow>
              <StatsTitle>Temps de conduite</StatsTitle>
              <StatsTime>
                {skills.PlayTime ? getTime(skills.PlayTime) : '0h0'}
              </StatsTime>
            </StatsRow>

            <StatsRowKM>
              <StatsTitle>Kilomètres parcourus</StatsTitle>
              <StatsTime>
                {skills.DistancePlay
                  ? `${parseInt(skills.DistancePlay / 1000)} km ${
                      parseInt(skills.DistancePlay) % 1000
                    }`
                  : '0km'}
              </StatsTime>
            </StatsRowKM>
            <SkillsContainer2>{getRatios()}</SkillsContainer2>
          </MainContainer>
        </MainLayout>
      </Bg>
    )
  } else {
    return (
      <Bg>
        <SideBar />
        <MainLayout>
          <MainTitle text='Statistiques' icon={statsLogo} />
          Loading ...
        </MainLayout>
      </Bg>
    )
  }
}

const withSaga = injectSaga({
  key: 'refreshSimuPlayers',
  saga: refreshSimuPlayersSaga,
})

const withConnect = connect(null, null)
const arg = compose(withSaga, withConnect)(StatsSimu)

// let arg = compose(withConnect)(StatsSimu)

export default withRouter(arg)
