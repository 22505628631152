/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { DELETE_SIMU_PLAYER, REFRESH_SIMU_PLAYERS, GET_SIMU_PLAYERS, CREATE_ARCHIVE_PLAYER, GET_ARCHIVE_PLAYERS, GET_DASHBOARD } from '../App/constants'
import { deleteSimuPlayerSuccess, deleteSimuPlayerError, refreshSimuPlayersError, refreshSimuPlayersSuccess, getSimuPlayersSuccess, getSimuPlayersError, createArchivePlayerSuccess, createArchivePlayerError, getArchivePlayersSuccess, getArchivePlayersError, getDashboardError, getDashboardSuccess  } from '../App/actions'
import config from '../../config/configurator'
import moment from 'moment'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'

const getStudentsByStatus = (arr, value) => {
  let archiveStudents = 0
  var i = 0;
  while (i < arr.length) {
    if (arr[i].status === value) {
      archiveStudents += 1
      arr.splice(i, 1)
    } else {
      ++i
    }
  }
  return {matchStatus: archiveStudents, remains: arr}
}

export function * deleteSimuPlayer ({ token, id, agencyid }) {
  // const url = `${serverPath}/getPlayers/${password}`
  const urlPlayerDelete = `${serverPath}/api/playerTGDs/${id}?access_token=${token}`
  // FIXME use api/agencies?filter[include][players]=sequenceProgressions&filter[where][id]=${id}&access_token=${id}
  const urlPlayers = `${serverPath}/api/playerTGDs?filter[where][agencyId]=${agencyid}&access_token=${token}`
  const urlStudents = `${serverPathTgdApi}/students?sortName=dateCreation&sortType=desc&pageSize=50&pageIndex=0`
  const urlTgdApiDelete = `${serverPathTgdApi}/students/${id}`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json, text/plain, */*')
  headers.append('Authorization', `Bearer ${token}`)
  const options = {
    method: 'DELETE',
    headers
  }

  try {
    if (isB2VM) {
      yield call(request, urlTgdApiDelete, options)
      let students = yield call(request, urlStudents, { method: 'GET', headers: {'Authorization': `Bearer ${token}`}  })
      students = students.map(student => ({created_at:student.dateCreation, ...student})) 
      yield put(deleteSimuPlayerSuccess(students))
    } else {
      // Call our request helper (see 'utils/request')
      yield call(request, urlPlayerDelete, { method: 'DELETE' })
      yield call(request, urlPlayers, { method: 'GET' })
      yield put(deleteSimuPlayerSuccess(/* players */))
    }

  } catch (err) {
    yield put(deleteSimuPlayerError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * deleteSimuPlayerSaga () {
  yield takeLatest(DELETE_SIMU_PLAYER, deleteSimuPlayer)
}

export function * refreshSimuPlayers ({ token, id, isAdmin }) {
  const filter = isAdmin ? '' : `filter[where][agencyId]=${id}&`
  const urlPlayers = `${serverPath}/api/playerTGDs?${filter}access_token=${token}`
  //const urlREMC = `${serverPath}/api/playerTGDs/${id}/agencySaveREMC?isAdmin=${isAdmin}&access_token=${token}`

  //let remc
  let players = []

  try {
    players = yield call(request, urlPlayers, { method: 'GET' })
    // if (config.title !== 'ECF' && config.features.simu) {
    //   remc = yield call(request, urlREMC, { method: 'GET' })
    //   players.forEach(pl => {
    //     const tmpProg = remc.filter(pr => { return pr.id === pl.id })
    //     pl.prog = tmpProg[0] ? tmpProg[0].save : {}
    //   })
    // }

    yield put(refreshSimuPlayersSuccess(players))
  } catch (err) {
    yield put(refreshSimuPlayersError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * refreshSimuPlayersSaga () {
  yield takeLatest(REFRESH_SIMU_PLAYERS, refreshSimuPlayers)
}

export function * getSimuPlayers ({filters, id, isAdmin, token, pageSize, pageIndex }) {
  const filter = isAdmin ? '' : `filter[where][agencyId]=${id}&`
  const urlPlayers = `${serverPath}/api/playerTGDs?${filter}access_token=${token}`
  const urlREMC = `${serverPath}/api/playerTGDs/${id}/agencySaveREMC?isAdmin=${isAdmin}&access_token=${token}`

  let url = `${serverPathTgdApi}/students`
  let countUrl = `${serverPathTgdApi}/students/count`

  console.log('filter: ', filters)

  if (filters.students) {
    if (filters.students.split(' ').length > 1) {
      url += `${url.includes('?') ? '&' : '?'}firstname=${filters.students.split(' ')[0]}&lastname=${filters.students.split(' ')[1]}`
      countUrl += `${countUrl.includes('?') ? '&' : '?'}firstname=${filters.students.split(' ')[0]}&lastname=${filters.students.split(' ')[1]}`
    } else {
      url += `${url.includes('?') ? '&' : '?'}firstname=${filters.students}`
      countUrl += `${countUrl.includes('?') ? '&' : '?'}firstname=${filters.students}`
    }
  }
  
  if (filters.agency && filters.agency !== 'all') {
    url += `${url.includes('?') ? '&' : '?'}agencyId=${filters.agency}`
    countUrl += `${countUrl.includes('?') ? '&' : '?'}agencyId=${filters.agency}`
  }
  
  if (filters.licence && filters.licence !== 'all') {
    url += `${url.includes('?') ? '&' : '?'}trainingType=${filters.licence}`
    countUrl += `${countUrl.includes('?') ? '&' : '?'}trainingType=${filters.licence}`
  }
  
  if (filters.date) {
    url += `${url.includes('?') ? '&' : '?'}dateCreation=${moment(filters.date).format('YYYY-MM-DD')}`
    countUrl += `${countUrl.includes('?') ? '&' : '?'}dateCreation=${moment(filters.date).format('YYYY-MM-DD')}`
  }
  
  if (filters.status && filters.status !== 'all') {
    const status = filters.status === 'Inactif' ? 0 : 1
    url += `${url.includes('?') ? '&' : '?'}status=${status}`
    countUrl += `${countUrl.includes('?') ? '&' : '?'}status=${status}`
  }

  if (filters.email) {
    url += `${url.includes('?') ? '&' : '?'}email=${filters.email}`
    countUrl += `${countUrl.includes('?') ? '&' : '?'}email=${filters.email}`
  }

  if (!filters.students && !filters.email && !filters.date && filters.agency === 'all' && filters.status === 'all' && filters.licence === 'all') {
    url += `?sortName=dateCreation&sortType=desc&pageSize=${pageSize}&pageIndex=${pageIndex}&status=noArchivedStudents`
  } else {
    url += `&sortName=dateCreation&sortType=desc&pageSize=${pageSize}&pageIndex=${pageIndex}&status=noArchivedStudents`
  }

  // we pass "status=noArchivedStudents" to the url to retrieve only unarchived students.
  // tgd-api take care of this case by excluding the status = 2 (status = { $ne: 2 }).

  let players = []
  let remc

  try {
    if (isB2VM) {
      players = yield call(request, url, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} })

      if (filters && filters.agency === 'all' && filters.licence === 'all' && filters.status === 'all' && filters.email === '' && filters.students === '') {

        console.log('GET EVERYTHING')

        let allPlayers = []

        let dataPerRequest = 5000
        let dataOffset = 0
  
        let hasMoreData = true
  
        while (hasMoreData){
          const totalSimuPlayers = yield call(request, `https://ecfsimu-tgdapi.tgdcloud.com/students?pageSize=${dataPerRequest}&pageIndex=${dataOffset}`, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} })
  
          allPlayers = allPlayers.concat(totalSimuPlayers)
          dataOffset++

          console.log(allPlayers.length)
    
          if (totalSimuPlayers.length === 0) {
            hasMoreData = false
          }
        }

        console.log('next')
    
        let displayPlayers = getStudentsByStatus(players, 2).remains
        let filteredStudents = getStudentsByStatus(allPlayers, 2)
        let totalInactiveStudents = getStudentsByStatus(allPlayers, 0).matchStatus
        let activeStudents = filteredStudents.remains
        let archivedStudents = filteredStudents.matchStatus

        const dashboardData = {
          totalStudents: allPlayers.length,
          totalActiveStudents: activeStudents.length - archivedStudents,
          totalInactiveStudents: totalInactiveStudents,
          totalArchiveStudents: archivedStudents
        }

        yield put(getSimuPlayersSuccess(displayPlayers, allPlayers.length, pageSize, pageIndex))
        yield put(getDashboardSuccess(dashboardData))

      } else {
        console.log('GET FILTERED COUNT')
        const totalSimuPlayers = yield call(request, countUrl, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} })
        let filteredPlayers = getStudentsByStatus(players, 2).remains
        yield put(getSimuPlayersSuccess(filteredPlayers, totalSimuPlayers, pageSize, pageIndex))
      }
    } else {
      players = yield call(request, urlPlayers, { method: 'GET' })

      // evaluations = yield call(request, urlEval, { method: 'GET' })
      if (config.title !== 'ECF' && config.features.simu) {
        remc = yield call(request, urlREMC, { method: 'GET' })
        players.forEach(pl => {
          const tmpProg = remc.filter(pr => { return pr.id === pl.id })
          pl.prog = tmpProg[0] ? tmpProg[0].save : {}
        })
      }
      yield put(getSimuPlayersSuccess(players))
    }
  } catch (err) {
    console.log(err)
    yield put(getSimuPlayersError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * getSimuPlayersSaga () {
  yield takeLatest(GET_SIMU_PLAYERS, getSimuPlayers)
}

export function * createArchivePlayer ({ data }) {

  const patchStudents = `${serverPathTgdApi}/students/${data.studentId}`
  const urlStudents = `${serverPathTgdApi}/students`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json, text/plain, */*')
  headers.append('Authorization', `Bearer ${data.agencyToken}`)

  const body = JSON.stringify({
    licenceData: {
      actualDriving: data.actualDrivingTotal,
      licenceDate: data.licenceDate,
      licenceAttempts: data.licenceAttempts
    },
    status: 2
  })

  const patchOptions = {
    method: 'PATCH',
    headers,
    body
  }

  try {
    yield call(request, patchStudents, patchOptions)
    let students = yield call(request, urlStudents, { method: 'GET', headers: {'Authorization': `Bearer ${data.agencyToken}`} })
    students = getStudentsByStatus(students, 2).remains
    yield put(createArchivePlayerSuccess(students))
  } catch (err) {
    yield put(createArchivePlayerError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * createArchivePlayerSaga () {
  yield takeLatest(CREATE_ARCHIVE_PLAYER, createArchivePlayer)
}

export function * getArchivePlayers ({ token }) {
  const archiveTgdApiUrl = `${serverPathTgdApi}/archives`

  if (isB2VM) {
    try {
      // Call our request helper (see 'utils/request')
      const archiveStudents = yield call(request, archiveTgdApiUrl, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} })
      yield put(getArchivePlayersSuccess(archiveStudents))
  
      // yield put(deleteSimuPlayerSuccess(/* players */)) no redux action for now
    } catch (err) {
      yield put(getArchivePlayersError(err))
    }
  }
 }

 export function * getArchivePlayerSaga () {
  yield takeLatest(GET_ARCHIVE_PLAYERS, getArchivePlayers)
}

export function * getDashboard ({ token }) {
  const dashboardUrl = `${serverPathTgdApi}/agencies/dashboard`

  if (isB2VM) {
    try {
      const dashboard = yield call(request, dashboardUrl, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} })
      yield put(getDashboardSuccess(dashboard))
    } catch (err) {
      yield put(getDashboardError(err))
    }
  }
 }

 export function * getDashboardSaga () {
  yield takeLatest(GET_DASHBOARD, getDashboard)
}
