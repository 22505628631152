import React, { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { useLocation, useHistory } from 'react-router-dom'
import { agencyEditData, deleteAgencySaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { agencyEdit, deleteAgency, hideModal } from '../App/actions'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import Pen from '../../images/pen.svg'
import ButtonUploadAws from '../../components/ButtonUploadAws'
import SuccessIcon from '../../images/Icone_agence.svg'
import UiDanger from '../../images/UiWarn.svg'
import ModalConfirm from '../../components/ModalConfirm'
import { Selector, Bg, MainLayout, Success, ImgSuccess, TextSuccess, SelectorContainer, FormAndLogoContainer, FormContainer, BoxContainer, Name, Input, DeleteInput, FeedBack, ButtonContainer, Button, DeleteButton, UploadContainer, UploadInput, UploadTitle } from './styledComponents'
import config from '../../config/configurator'

const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'

const AgencyEdit = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const agencyToken = useSelector((state) => state.toJS().global.agencytoken)
  const error = useSelector((state) => state.toJS().global.error)
  const agencies = useSelector((state) => state.toJS().global.agencies)
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)
  const successStore = useSelector((state) => state.toJS().global.success)
  let agency

  const id = location.query ? location.query.id : location.pathname.split('/')[3]
  if (isB2VM) {
    agency = agencies ? agencies.find((a) => a.agencyId === id) : ''
  } else {
    agency = agencies ? agencies.find((a) => a.id.toString() === id) : ''
  }

  const [agencyId, setAgencyId] = useState()
  const [autorisation, setAutorisation] = useState(agency.autorisation)
  console.log('auto',autorisation)
  const [agencyPassword, setAgencyPassword] = useState('')
  const [repeatPassword, setRepeatPassowrd] = useState('')
  const [agencyName, setAgencyName] = useState(agency.agencyname || agency.agencyName)
  const [wrongRepeat, setWrongRepeat] = useState(false)
  const [errorCode, setErrorCode] = useState(0)
  const [email, setEmail] = useState(agency.email)
  const [notificationEmail, setNotificationEmail] = useState(agency.notificationEmail)
  const [logo, setLogo] = useState()
  const [enable, setEnable] = useState()
  // const [delName, setDelName] = useState(false) /* TODO: See when we add the delete icon for empty inputs */
  // const [delMail, setDelMail] = useState(false)
  // const [delPsw, setDelPsw] = useState()
  // const [delRep, setDelRep] = useState()
  const [warnMail, setWarnMail] = useState()
  const [warnNotificationEmail, setWarnNotificationEmail] = useState()
  const [backgroundColor, setBackgroundColor] = useState()
  const [color, setColor] = useState()
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState()
  const [borderColorMail, setBorderColorMail] = useState()
  const [borderColorNotifMail, setBorderColorNotifMail] = useState()
  const [evalScaleDisplay, setEvalScaleDisplay] = useState()
  const [success, setSuccess] = useState(false)
  const [entity, setEntity] = useState(agency.entity || 'TGD')

  useEffect(() => {
    displayModal(successStore)
    checkPassword()
    checkError()
    setAgencyId(agency.id || agency.agencyId)
    setEvalScaleDisplay(
      agency.evalScaleDisplay === 'hours' ?
      1 : agency.evalScaleDisplay === 'seq' ?
        2 : 0
      )
    // eslint-disable-next-line
  }, [agency.id, agencyPassword, repeatPassword, successStore, agencies])

  const displayModal = (show) => {
    if(show){
      setSuccess(true)
      setTimeout(() => {
        dispatch(hideModal())
        setSuccess(false)
      }, 2000);
    }
  }

  const checkPassword = () => {
    if (agencyPassword && repeatPassword) {
      if (agencyPassword !== repeatPassword) {
        setWrongRepeat(true)
        setBackgroundColor('#EFF0F6')
        setColor('#868383')
        setEnable(false)
      } else if (agencyPassword && repeatPassword && (agencyPassword === repeatPassword)) {
        setWrongRepeat(false)
        setBackgroundColor('#00C4F3')
        setColor('black')
        setEnable(true)
      }
      if (agencyPassword === repeatPassword) {
        setWrongRepeat(false)
      }
    }
  }

  const checkError = () => {
    if (error !== undefined) {
      if (error.response !== undefined) {
        if (errorCode !== error.response.status) {
          setErrorCode(error.response.status)
        }
      }
    }
  }

  function verification () {
    // Look if something change in the form.
    if (agencyName) {
      if (!wrongRepeat) {
      // Check if the mail format is valid
        if (emailCheck(email, null) && emailCheck(null, notificationEmail)) {
          setBackgroundColor('#00C4F3')
          setColor('black')
          setEnable(true)
        } else {
          setBackgroundColor('#EFF0F6')
          setColor('#868383')
          setEnable(false)
        }
      }
    } else {
      setBackgroundColor('#868383')
      setColor('#868383')
      setEnable(false)
    }
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      clickHandlerAccount()
    }
  }

  function clickHandlerAccount () {
    const agencyData = {
      id: agencyId,
      agencyname: agencyName,
      email: email,
      notificationEmail: notificationEmail,
      password: agencyPassword,
      entity: entity,
      token: agencyToken,
      logo: logo,
      evalScaleDisplay: evalScaleDisplay,
      autorisation: autorisation
    }
    // remove null keys to avoid resetting unset data
    Object.keys(agencyData).forEach(k => {
      if(agencyData[k] == null) {
        delete agencyData[k]
      }
    })
    // remove empty passwords
    if(agencyData.password === '') { delete agencyData.password }
    dispatch(agencyEdit(agencyData))
    setErrorCode(200)
    setBackgroundColor('#EFF0F6')
    setColor('#868383')
    setEnable(false)
    setAgencyPassword('')
    setRepeatPassowrd('')

    setTimeout(() => {
      setErrorCode(0)
    }, 2000)
  }

  function confirmDelete (id, action) {
    setShowModal(true)
    setModalText(`${action}`)
  }

  const deleteCurrentAgency = (id) => {
    dispatch(deleteAgency(agencyToken, id))
    history.push('/agencies')
  }

  function handleChangeAgency (e) {
    history.push(`/agency/edit/${e.value}`)
    let newAgency
    if (isB2VM) {
      newAgency = agencies.find(ag => ag.agencyId === e.value)
    } else {
      newAgency = agencies.find(ag => ag.id.toString() === e.value)
    }
    setAgencyId(e.value)
    setAgencyName(newAgency.agencyname || newAgency.agencyName)
    setEmail(newAgency.email)
    setNotificationEmail(newAgency.notificationEmail)
    setAutorisation(newAgency.autorisation || newAgency.agencyName)
    setLogo(newAgency.logo)
    setEntity(newAgency.entity || 'TGD')
    setEvalScaleDisplay(newAgency.evalScaleDisplay)
    setBackgroundColor('#EFF0F6')
    setColor('#868383')
    setEnable(false)
    if (isAdmin) {
      setAgencyId(newAgency.agencyId)
    }
  }

  function displayAgencySelector () {      
    if (agency) {
      const agencyValues = []
      agencies.forEach((ag) => agencyValues.push({ value: ag.agencyId, label: ag.agencyName }))
      let currentAgencyIndex = agencyValues.indexOf(agencyValues.find((ag) => ag.value === agencyId))

      if (!agencyValues[currentAgencyIndex]) return null
      return (
        <div>
          <Selector
            classNamePrefix='react-select'
            multiple={false}
            isSearchable={false}
            defaultValue={agencyValues[currentAgencyIndex]}
            onChange={(e) => {handleChangeAgency(e)}}
            options={agencyValues}>
          </Selector>
        </div>
      )
    }
  }


  function emailCheck (email, notifMail) {
    if (email && notifMail == null) {
      if (email !== null && email !== '') {
        const expReg = /\S+@\S+\.\S+/
        const result = email.match(expReg)
        if (result == null) {
          setBorderColorMail('#FF0000')
          setWarnMail(true)
          return false
        }
      }
      setBorderColorMail('#B3ABBC')
      setWarnMail(false)
      return true
    } else if (email == null && notifMail) {
      if (notifMail !== null && notifMail !== '') {
        const expReg = /\S+@\S+\.\S+/
        const result = notifMail.match(expReg)
        if (result == null) {
          setBorderColorNotifMail('#FF0000')
          setWarnNotificationEmail(true)
          return false
        }
      }
      setBorderColorNotifMail('#B3ABBC')
      setWarnNotificationEmail(false)
      return true
    }
    return true
  }

  return (
    <Bg>
      <SideBar />
      <MainLayout>
        {success
          ? <Success>
            <ImgSuccess src={SuccessIcon} />
            <TextSuccess>{agencyName} a été modifiée.</TextSuccess>
          </Success>
          : null}
        <MainTitle text='Le compte agence' icon={Pen} />
        <ModalConfirm show={showModal} confirmText='Supprimer' text={modalText} name={agencyName} confirm={() => deleteCurrentAgency(agencyId)} cancel={() => setShowModal(false)} />
        <SelectorContainer>
          {displayAgencySelector()}
        </SelectorContainer>
        <FormAndLogoContainer style={{ justifyContent: config.layouts.agencyCreate && (config.layouts.agencyCreate.logo === false) ? 'center' : 'unset' }}>
          <FormContainer style={{ marginLeft: config.layouts.agencyCreate && (config.layouts.agencyCreate.logo === false) ? '0' : '17.8rem' }}>
            <BoxContainer>
              <Name>Nom de l'agence</Name>
              <Input
                value={agencyName}
                onChange={(e) => { setAgencyName(e.target.value); verification() }}
              />
              {/* {delAgencyName && agencyName ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => emptyInput('name')} />) : ''} */}
            </BoxContainer>
            {config.title !== 'ML ADMIN'
              ? <>
              { isB2VM ? null : 
              <BoxContainer>
              <Name>Email</Name>
              <Input
                value={email}
                type='text'
                style={{ borderColor: borderColorMail }}
                onChange={(e) => { setEmail(e.target.value); emailCheck(email, null); verification() }}
              />
              {/* {delMail && email ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('email'); setState({ borderColorMail: '#B3ABBC', warnMail: false })}} />) : ''} */}
              {(warnMail || errorCode === 409) && /*! delMail && */ email ? (<DeleteInput style={{ bottom: '45%', right: '2.5%', height: '1rem' }} src={UiDanger} />) : ''}
              <FeedBack style={{ opacity: warnMail ? '1' : '0' }}>
                Email non valide
              </FeedBack>
              </BoxContainer>
              }
              {config.features.simu ? 
                <BoxContainer>
                  <Name>Email de notification</Name>
                  <Input
                    value={notificationEmail}
                    type='text'
                    style={{ borderColor: borderColorNotifMail }}
                    onChange={(e) => { setNotificationEmail(e.target.value); emailCheck(null, notificationEmail); verification() }}
                  />
                  {/* {delMail && email ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('email'); setState({ borderColorMail: '#B3ABBC', warnMail: false })}} />) : ''} */}
                  {(warnNotificationEmail || errorCode === 409) && /*! delMail && */ notificationEmail ? (<DeleteInput style={{ bottom: '45%', right: '2.5%', height: '1rem' }} src={UiDanger} />) : ''}
                  <FeedBack style={{ opacity: warnNotificationEmail ? '1' : '0' }}>
                  Email non valide
                  </FeedBack>
                </BoxContainer>
                : null
              }
                </>
              : null}
            <BoxContainer>
              <Name>Mot de passe</Name>
              <Input
                placeholder='••••••'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
                value={agencyPassword}
                type='text'
                onChange={(e) => { setAgencyPassword(e.target.value); verification() }}
              />
              {/* {delPsw && agencyPassword ? ( <DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('agencyPassword'); setState({ borderColorMail: '#B3ABBC', warnPsw: false })}} />) : ''} */}
              {wrongRepeat/*  && !delPsw */ ? (<DeleteInput style={{ bottom: '45%', right: '3%', height: '1rem' }} src={UiDanger} />) : null}
            </BoxContainer>
            <BoxContainer>
              <Name>Répéter le mot de passe</Name>
              <Input
                placeholder='••••••'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
                onKeyPress={onKeyPress}
                value={repeatPassword}
                type='text'
                onChange={(e) => { setRepeatPassowrd(e.target.value); verification() }}
              />
              {/* {delRep && repeatPassword ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('repeatPassword'); setState({ borderColorMail: '#B3ABBC', warnPsw: false })}} />) : ''} */}
              {wrongRepeat /* && !delRep */ ? (<DeleteInput style={{ bottom: '44%', right: '3%', height: '1rem' }} src={UiDanger} />) : null}
              <FeedBack style={{ opacity: wrongRepeat ? '1' : '0' }}>
                Les mots de passes ne correspondent pas
              </FeedBack>
            </BoxContainer>
            {config.title === 'PP ADMIN' ?
            <BoxContainer>
              <Name>Type d'agence</Name>
              <Input
                onKeyPress={onKeyPress}
                value={entity}
                type='text'
                onChange={(e) => { setEntity(e.target.value); verification()}}
              />
            </BoxContainer>
                : ''} 
            <BoxContainer>
            <Name>N° d'autorisation d'enseigner</Name>
            <Input
              onKeyPress={onKeyPress}
              value={autorisation}
              onChange={(e) => {setAutorisation(e.target.value); verification()}}
            />
          </BoxContainer>
            <ButtonContainer>
              <DeleteButton
                onClick={() => confirmDelete(agencyId, 'Attention, vous allez supprimer définitivement l\'agence ', agencyName)}
              >
                Supprimer
              </DeleteButton>
              <Button
                disabled={!enable}
                data-type='premium'
                style={{ backgroundColor: backgroundColor, color: color, cursor: enable ? 'pointer' : 'unset' }}
                onClick={() => clickHandlerAccount()}
              >
                Modifier l'agence
              </Button>
            </ButtonContainer>
          </FormContainer>
          {config.layouts.agencyCreate.logo === true
            ? <UploadInput>
              <UploadContainer>
                <UploadTitle>Ajouter / Modifier un logo</UploadTitle>
                {/* This pop up will show if we change media type while the previous media is still on aws */}
                {/* {showPopUpWarning && (
          <PopUp
            text='Supprimez d abord l ancien media'
            textNO='blah ok'
            callbackNo={() => setState({ showPopUpWarning: false })}
          />
        )} */}
                <ButtonUploadAws
                  prevFilename={logo}
                  onUpload={(fileName) => {
                    setLogo(fileName)
                    setBackgroundColor('#00C4F3')
                    setColor('black')
                    setEnable(true)
                  }}
                  onDelete={() => {
                    setLogo(null)
                    setBackgroundColor('#00C4F3')
                    setColor('black')
                    setEnable(true)
                  }}
                  folder='agencyLogos/'
                  width='21.3rem'
                  height='12.1rem'
                />
              </UploadContainer>
              </UploadInput>
            : null}
        </FormAndLogoContainer>
      </MainLayout>
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'AgencyEdit', saga: agencyEditData })
const withDeleteSaga = injectSaga({
  key: 'AgencyDelete',
  saga: deleteAgencySaga
})
const arg = compose(withSaga, withDeleteSaga, withConnect)(AgencyEdit)

export default arg
