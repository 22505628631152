/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_PLAYERS } from '../App/constants'
import { getPlayersSuccess, getPlayersError } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API

export function * getPlayers ({ token, id, isAdmin }) {
  // const url = `${serverPath}/getPlayers/${password}`
  // FIXME use api/agencies?filter[include][players]=sequenceProgressions&filter[where][id]=${id}&access_token=${id}
  const filter = isAdmin ? '' : `filter[where][agencyId]=${id}&`
  const urlPlayers = `${serverPath}/api/players?${filter}access_token=${token}`
  const urlProgressions = `${serverPath}/api/sequenceProgressions?access_token=${token}`
  const urlSequences = `${serverPath}/api/sequences?access_token=${token}`

  try {
    // Call our request helper (see 'utils/request')
    const playersRaw = yield call(request, urlPlayers, { method: 'GET' })
    const progressions = yield call(request, urlProgressions, { method: 'GET' })
    const sequencesRaw = yield call(request, urlSequences, { method: 'GET' })

    const sequences = {}
    sequencesRaw.forEach(seq => {
      sequences[seq.id] = seq
    })

    let players = {}
    playersRaw.forEach(player => {
      players[player.id.toString()] = player
      players[player.id.toString()].sequenceCount = sequencesRaw.length // FIXME when journey arrive
      players[player.id.toString()].sequenceDoneCount = 0
    })
    console.log('progressions', progressions)
    progressions.forEach(p => {
      const id = p.playerId
      if (players[id] == null) {
        return
      }
      if (players[id].scoreDetails == null) { players[id].scoreDetails = [] }
      players[id].scoreDetails.push({
        title: sequences[p.sequenceId],
        id: p.sequenceId,
        isDone: p.sectionScores.reduce((a, v) => (v && v.score != null) ? a : false, true) // FIXME
      })
      players[id].sequenceDoneCount = players[id].scoreDetails.length
    })

    players = Object.keys(players).map(k => players[k])
    yield put(getPlayersSuccess(players, progressions, sequencesRaw))
  } catch (err) {
    yield put(getPlayersError(err))
  }
}

/**
  * Root saga manages watcher lifecycle
  */
export function * getPlayersSaga () {
  yield takeLatest(GET_PLAYERS, getPlayers)
}
