import React, { useEffect, useState } from 'react'
import '../../fonts/fonts.css'
import SideBar from '../SideBar'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { useLocation, useHistory } from 'react-router-dom'
import injectSaga from '../../utils/injectSaga'
import { playerEditData, deletePlayerSaga } from './saga'
import { playerEdit, deletePlayer } from '../App/actions'
import MainTitle from '../../components/MainTitle'
import pen from '../../images/pen.svg'
import UiCross from '../../images/close.svg'
import UiDanger from '../../images/UiWarn.svg'
import SuccessIcon from '../../images/successface.svg'
import ArrowDown from '../../images/ArrowDownSelector.svg'
import ModalConfirm from '../../components/ModalConfirm'
import { Selector, Opt, Success, ImgSuccess, TextSuccess, Bg, MainLayout, SelectorStudentContainer, ArrowStudent, InfoContainer, Info, Parcours, Stats, FormContainer, BoxContainer, Name, SelectorAgencyContainer, ArrowAgency, InputDisplayer, Input, FeedBack, DeleteInput, ButtonContainer, DeleteButton, Button } from './styledComponents'
import { set } from 'lodash'

const PlayerEdit = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const players = useSelector((state) => state.toJS().global.players)
  const error = useSelector((state) => state.toJS().global.error)
  const loading = useSelector((state) => state.toJS().global.loading)
  const token = useSelector((state) => state.toJS().global.agencytoken)
  const agencies = useSelector((state) => state.toJS().global.agencies)
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)

  const id = location.query ? location.query.id : location.pathname.split('/')[3]
  const player = players ? players.find(player => player.id.toString() === id) : ''

  const [success, setSuccess] = useState(false)
  const [password, setPassword] = useState('')
  const [repPassword, setRepPassword] = useState('')
  const [wrongRepeat, setWrongRepeat] = useState(false)
  const [playerId, setPlayerId] = useState(id)
  const [playerAgency, setPlayerAgency] = useState(player ? player.agencyId : '')
  const [lastName, setLastName] = useState(player.lastname)
  const [firstName, setFirstName] = useState(player.firstname)
  const [email, setEmail] = useState(player ? player.email : '')
  const [colors, setColors] = useState([' ', ' ', ' '])
  const [borderBottom, setBorderBottom] = useState([' ', ' ', ' '])
  const [verif, setVerif] = useState(false)
  const [check, setCheck] = useState(false)
  const [emailError, setEmailError] = useState('Email non valide')
  const [del, setDel] = useState({ lastname: false, firstname: false, mail: false, psw: false, repPsw: false })
  const [warn, setWarn] = useState({ lastname: false, firstname: false, mail: false, psw: false, repPsw: false })
  const [showModal, setShowModal] = useState(false)
  const [errorCode, setErrorCode] = useState('')
  const [borderColor, setBorderColor] = useState()
  const [modalText, setModalText] = useState()
  const [modalName, setModalName] = useState()

  console.log(player)

  useEffect(() => {
    checkPassword()
    checkError()
    setFirstName(player.firstname)
    setLastName(player.lastname)
    setEmail(player.email)
    setPlayerAgency(player.agencyId)
    // eslint-disable-next-line
  }, [player.firstname, error, repPassword, password])

  const checkPassword = () => {
    if (repPassword && password && (password !== repPassword)) {
      setWrongRepeat(true)
    } else {
      setWrongRepeat(false)
    }
  }

  const checkError = () => {
    if (!loading && check) {
      if (error) {
        if (error.response !== undefined && errorCode !== error.response.status) {
          setSuccess(false)
          setWarn({ mail: true })
          setCheck(false)
          setEmailError('Cet email est déjà pris')
          setErrorCode(error.response.status)
        }
      } else {
        setTimeout(() => { setSuccess(false) }, 3000)
        setCheck(false)
        setSuccess(true)
        setDel({})
        setWarn({})
      }
    }
  }

  function handleClick () {
    setErrorCode(0)
    if (!verif) { setWarn({ lastname: true }) } else {
      const id = playerId
      dispatch(playerEdit({ id, firstname: firstName, lastname: lastName, email: email, password: password, agencyid: playerAgency, token: token, type: location.state }))
      setCheck(true)
      setRepPassword('')
      setVerif(false)
    }
  }

  function verification () {
    if (lastName && firstName && email && (password === repPassword)) {
      if (!emailCheck()) {
        setVerif(true)
      }
    } else {
      setVerif(false)
    }
  }

  function onKeyPress (event) {
    if (event.key === 'Enter') {
      if (verif) { handleClick() }
    }
  }

  function handleChange (e) {
    history.push(`/players/edit/${e.target.value}`)
    const newPlayer = players.find(player => player.id.toString() === e.target.value)
    setPlayerId(e.target.value)
    setFirstName(newPlayer.firstname)
    setLastName(newPlayer.lastname)
    setEmail(newPlayer.email)
    if (isAdmin) {
      setPlayerAgency(newPlayer.agencyId)
    }
  }

  function displaySelector () {
    if (players) {
      return (
        <Selector style={{ marginTop: '4rem', outline: 'none' }} onChange={(e) => { handleChange(e) }} value={id}> {/* value={[player.get('id'), player.get('firstname'), player.get('lastname'), player.get('email'), player.get('agencyId')] */}
          {players.map((pl, i = 0) => {
            if (pl.foreignKey === 'fkeditor') {
              return null
            }
            return <Opt key={i} value={pl.id}>{pl.firstname + ' ' + pl.lastname}</Opt>
          })}
        </Selector>
      )
    }
  }

  function handleChangeAgency (e) {
    setPlayerAgency(e.target.value)
    setVerif(true)
  }

  function displayAgencySelector () {
    if (agencies) {
      const agenciesArr = agencies
      agenciesArr.splice(0, 0, [1])
      return (
        <Selector style={{ width: '100%' }} onChange={(e) => { handleChangeAgency(e) }} value={playerAgency}>
          {agenciesArr.map((ag, i = 0) => {
            if (i === 0) {
              return <Opt key={i} value='Aucune agence'>Aucune agence</Opt>
            } else {
              return <Opt key={i} value={ag.id}>{ag.username}</Opt>
            }
          })}
        </Selector>
      )
    }
  }

  function emailCheck () {
    if (!email) {
      return
    }
    const expReg = /\S+@\S+\.\S+/
    const result = email.match(expReg)
    if (result == null) {
      setBorderColor('#FF0000')
      setWarn({ mail: true })
      setEmailError('Mail non valide')
      return true
    }
    setBorderColor('#B3ABBC')
    setWarn({ mail: false })
    return false
  }

  // switch color in the infoContainer to know where we are
  function onClickColor (e) {
    setColors([
      e === 0 ? '#166DD8' : 'black',
      e === 1 ? '#166DD8' : 'black',
      e === 2 ? '#166DD8' : 'black'
    ])
    setBorderBottom([
      e === 0 ? '3px solid #166DD8' : 'black',
      e === 1 ? '3px solid #166DD8' : 'black',
      e === 2 ? '3px solid #166DD8' : 'black'
    ])
  }
  function emptyInput (type) {
    set[type]('')
  }
  function renderSuccess () {
    return (
      <Success>
        <ImgSuccess src={SuccessIcon} />
        <TextSuccess>L'élève à bien été modifié !</TextSuccess>
      </Success>
    )
  }

  function confirmDelete (id, action, firstname) {
    setShowModal(true)
    setPlayerId(id)
    setModalText(`${action}`)
    setModalName(`${firstname}`)
  }

  function deleteCurrentPlayer (id) {
    dispatch(deletePlayer(token, id, playerAgency))
    setTimeout(() => {
      history.push('/players')
    }, 100)
  }

  return (
    <Bg>
      <SideBar />
      <MainLayout>
        {success
          ? renderSuccess()
          : <div />}
        <MainTitle text='Le compte élève' icon={pen} />
        <ModalConfirm show={showModal} confirmText='Supprimer' text={modalText} name={modalName} confirm={() => deleteCurrentPlayer(playerId)} cancel={() => setShowModal(false)} />
        <SelectorStudentContainer>
          <ArrowStudent src={ArrowDown} />
          {displaySelector()}
        </SelectorStudentContainer>
        <InfoContainer>
          <Info
            style={{
              color: colors[0],
              borderBottom: borderBottom[0]
            }}
            onClick={(e) => onClickColor(0)}
          >
            Informations
          </Info>
          <Parcours
            style={{
              color: colors[1],
              borderBottom: borderBottom[1]
            }}
            onClick={(e) => onClickColor(1)}
          >
            Parcours
          </Parcours>
          <Stats
            to={`stats/${id}`}
            style={{
              color: colors[2],
              borderBottom: borderBottom[2]
            }}
            onClick={(e) => onClickColor(2)}
          >
            Statistiques
          </Stats>
        </InfoContainer>
        <FormContainer>
          {isAdmin
            ? <BoxContainer>
              <Name>Agence</Name>
              <SelectorAgencyContainer>
                <ArrowAgency src={ArrowDown} />
                {displayAgencySelector()}
              </SelectorAgencyContainer>
            </BoxContainer>
            : <div />}
          <BoxContainer>
            <Name>Nom</Name>
            <InputDisplayer>
              <Input
                autocomplete='off'
                value={lastName}
            // onFocus={() => {showDelete('lastname', true)}}
            // onBlur={() => {setTimeout(()=>{showDelete('lastname', false)}, 100)}}
                onKeyUp={() => verification()}
                onChange={(e) => setLastName(e.target.value)}
              />
              {/* {del.lastname ? <DeleteInput src={UiCross}  onClick={() => emptyInput('lastname')}/> : ''} */}
            </InputDisplayer>
            <FeedBack style={{ opacity: warn.lastname ? '1' : '0' }}>Tous les champs ne sont pas valides</FeedBack>
          </BoxContainer>
          <BoxContainer>
            <Name>Prénom</Name>
            <InputDisplayer>
              <Input
                autocomplete='off'
            // onFocus={() => {showDelete('firstname',  true)}}
            // onBlur={() => {setTimeout(()=>{showDelete('firstname', false)}, 100)}}
                onKeyUp={() => verification()}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {/* {false ? <DeleteInput style={{ height: '1rem' }} src={UiDanger} /> : ''} */}
              {/* {del.firstname ? <DeleteInput src={UiCross} onClick={() => emptyInput('firstname')}/> : ''} */}
            </InputDisplayer>
            <FeedBack style={{ opacity: errorCode === 409 ? '0' : '0' }}>{firstName + ' ' + lastName + 'existe déjà'}</FeedBack>
          </BoxContainer>
          <BoxContainer>
            <Name>Email</Name>
            <InputDisplayer>
              <Input
                autocomplete='off'
                style={{ borderColor: borderColor }}
            // onFocus={() => {showDelete('mail', true)}}
            // onBlur={() => {setTimeout(()=>{showDelete('mail', false)}, 100)}}
                onKeyUp={() => verification()} value={email}
                onChange={(e) => { setEmail(e.target.value); emailCheck() }}
              />
              {/* {del.mail ? <DeleteInput src={UiCross} onClick={() => emptyInput('email')}/> : ''} */}
              {(warn.mail || errorCode === 422) && !del.mail ? <DeleteInput style={{ bottom: '53.8%', right: '3%', height: '1rem' }} src={UiDanger} /> : ''}
              {del.mail ? <DeleteInput src={UiCross} onClick={() => emptyInput('email')} /> : ''}
            </InputDisplayer>
            <FeedBack style={{ opacity: warn.mail ? '1' : '0' }}>{emailError}</FeedBack>
          </BoxContainer>
          <BoxContainer>
            <Name>Mot de passe</Name>
            <InputDisplayer>
              <Input
                autocomplete='off'
                placeholder='••••••'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
            // onFocus={() => {showDelete('psw', true)}}
            // onBlur={() => {setTimeout(()=>{showDelete('psw', false)}, 100)}}
                onKeyUp={() => verification()}
                onKeyPress={() => onKeyPress}
                value={password}
                type='text'
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* {del.psw ? <DeleteInput src={UiCross} onClick={() => emptyInput('password')}/> : ''} */}
              {wrongRepeat && !del.psw ? (<DeleteInput style={{ bottom: '36.2%', right: '3%', height: '1rem' }} src={UiDanger} />) : null}
            </InputDisplayer>
          </BoxContainer>
          <BoxContainer>
            <Name>Répeter le mot de passe</Name>
            <InputDisplayer>
              <Input
                autocomplete='off'
                placeholder='••••••'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
            // onFocus={() => {showDelete('repPsw', true)}}
            // onBlur={() => {setTimeout(()=>{showDelete('repPsw', false)}, 100)}}
                onKeyUp={() => verification()}
                onKeyPress={() => onKeyPress}
                value={repPassword}
                type='text'
                onChange={(e) => setRepPassword(e.target.value)}
              />
              {/* {del.repPsw ? <DeleteInput src={UiCross} onClick={() => emptyInput('repPassword')}/> : ''} */}
              {wrongRepeat && !del.repPsw ? (<DeleteInput style={{ bottom: '16.7%', right: '3%', height: '1rem' }} src={UiDanger} />) : null}
            </InputDisplayer>
            <FeedBack style={{ opacity: wrongRepeat ? '1' : '0' }}>
              Les mots de passes ne correspondent pas
            </FeedBack>
          </BoxContainer>
          <ButtonContainer>
            <DeleteButton onClick={() => confirmDelete(id, 'Vous allez supprimer', (lastName + ' ' + firstName))}>Supprimer</DeleteButton>
            <Button disabled={!verif} data-type='premium' style={{ backgroundColor: verif ? '#00C4F3' : '#EFF0F6', color: verif ? 'black' : '#868383', cursor: verif ? 'pointer' : 'unset' }} onClick={() => handleClick()}>
              Enregistrer les modifications
            </Button>
          </ButtonContainer>
        </FormContainer>
      </MainLayout>
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'playerEdit', saga: playerEditData })
const withDeleteSaga = injectSaga({ key: 'deletePlayer', saga: deletePlayerSaga })
// const withResfreshSaga = injectSaga({key: 'refreshEPlayers', saga: refreshEPlayersSaga})

// let arg = compose(withSaga, withResfreshSaga, withConnect)(PlayerEdit)
const arg = compose(withSaga, withDeleteSaga, withConnect)(PlayerEdit)

export default arg
