const strize = require('../strize')

module.exports = {
  title: '3T5 ADMIN',
  imgs: {
    root: '../../images3T5'
  },
  features: {
    elearning: true,
    simu: false,
    eval: true
  },
  bucket: {
    player: {
      bucket: strize(''),
      region: strize('')
    },
    editor: {
      bucket: strize(''),
      region: strize(''),
      key: strize(''),
      secret: strize('')
    }
  },
  colors: {
    ActionMail:'#dadada'
  },
  layouts: {
  }
}
