// const fs = require('fs')
const configs = require('./configs.js')
var merge = require('deepmerge')
const custo = configs[process.env.REACT_APP_PROJECT || 'root']
const root = configs['root']
const config = merge(root, custo)
console.log('******* CURRENT CONFIG')
console.log(config)
console.log('*******')

export default config
