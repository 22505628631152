import { call, put, takeLatest } from 'redux-saga/effects'
import { RESET_PASSWORD, CHECK_TEMPORARY_TOKEN } from '../App/constants'
import {
  resetPasswordSuccess,
  resetPasswordError,
  checkTemporaryTokenSuccess,
  checkTemporaryTokenError
} from '../App/actions'

import request from '../../utils/request'

const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
// const serverPath = `https://ver-client.tgd-test.site`
/**
 * Github repos request/response handler
 */

export function * resetPassword ({studentId, token, password}) {
  const resetPasswordUrl = `${serverPathTgdApi}/auth/confirmResetPassword`
  console.log('SAGA:', studentId, token, password)

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  const body = JSON.stringify({studentId, token, password})

  const postOptions = {
    method: 'POST',
    headers,
    body
  }

  try {
    const student = yield call(request, resetPasswordUrl, postOptions)
    yield put(resetPasswordSuccess(student))
  } catch (err) {
    console.log(err)
    yield put(resetPasswordError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * resetPasswordSaga () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(RESET_PASSWORD, resetPassword)
}

export function * checkTemporaryToken ({token, studentId}) {
  const checkTmpTokenUrl = `${serverPathTgdApi}/auth/checkTemporaryToken`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  const body = JSON.stringify(token)

  const postOptions = {
    method: 'POST',
    headers,
    body
  }

  try {
    yield call(request, checkTmpTokenUrl, postOptions)
    yield put(checkTemporaryTokenSuccess(token, studentId))
  } catch (err) {
    console.log(err)
    yield put(checkTemporaryTokenError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * checkTemporaryTokenSaga () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(CHECK_TEMPORARY_TOKEN, checkTemporaryToken)
}
