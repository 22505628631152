import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './app/containers/App/index'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import configureStore from './configureStore'
import { ConnectedRouter } from 'connected-react-router/immutable'

const initialState = {}
const history = createBrowserHistory()
const store = configureStore(initialState, history)

ReactDOM.render(
  <>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App history={history} store={store} />
      </ConnectedRouter>
    </Provider>
  </>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
