/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { DELETE_PLAYER, REFRESH_PLAYERS, GET_TOKEN } from '../App/constants'
import { deletePlayerSuccess, deletePlayerError, refreshPlayersError, refreshPlayersSuccess, getTokenSuccess, getTokenError } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API

export function * deletePlayer ({ token, id, agencyid }) {
  // const url = `${serverPath}/getPlayers/${password}`
  const urlPlayerDelete = `${serverPath}/api/players/${id}?access_token=${token}`
  // FIXME use api/agencies?filter[include][players]=sequenceProgressions&filter[where][id]=${id}&access_token=${id}
  const urlPlayers = `${serverPath}/api/players?filter[where][agencyId]=${agencyid}&access_token=${token}`
  const urlProgressions = `${serverPath}/api/sequenceProgressions?access_token=${token}`
  const urlSequences = `${serverPath}/api/sequences?access_token=${token}`

  try {
    // Call our request helper (see 'utils/request')
    yield call(request, urlPlayerDelete, { method: 'DELETE' })
    const playersRaw = yield call(request, urlPlayers, { method: 'GET' })
    const progressions = yield call(request, urlProgressions, { method: 'GET' })
    const sequencesRaw = yield call(request, urlSequences, { method: 'GET' })

    const sequences = {}
    sequencesRaw.forEach(seq => {
      sequences[seq.id] = seq
    })

    let players = {}
    playersRaw.forEach(player => {
      players[player.id.toString()] = player
      players[player.id.toString()].sequenceCount = sequencesRaw.length // FIXME when journey arrive
      players[player.id.toString()].sequenceDoneCount = 0
    })

    progressions.forEach(p => {
      const id = p.playerId
      if (players[id] == null) {
        return
      }
      if (players[id].scoreDetails == null) { players[id].scoreDetails = [] }
      players[id].scoreDetails.push({
        title: sequences[p.sequenceId],
        id: p.sequenceId,
        isDone: p.sectionScores.reduce((a, v) => (v && v.score != null) ? a : false, true) // FIXME
      })
      players[id].sequenceDoneCount = players[id].scoreDetails.length
    })

    players = Object.keys(players).map(k => players[k])
    yield put(deletePlayerSuccess(/* players */))
  } catch (err) {
    yield put(deletePlayerError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * deletePlayerSaga () {
  yield takeLatest(DELETE_PLAYER, deletePlayer)
}

export function * refreshPlayers ({ token, id, isAdmin }) {
  console.log('refresh', token, id, isAdmin)
  // const url = `${serverPath}/getPlayers/${password}`
  // FIXME use api/agencies?filter[include][players]=sequenceProgressions&filter[where][id]=${id}&access_token=${id}
  const filter = isAdmin ? '' : `filter[where][agencyId]=${id}&`
  const urlPlayers = `${serverPath}/api/players?${filter}access_token=${token}`
  const urlProgressions = `${serverPath}/api/sequenceProgressions?access_token=${token}`
  const urlSequences = `${serverPath}/api/sequences?access_token=${token}`

  try {
    // Call our request helper (see 'utils/request')
    const playersRaw = yield call(request, urlPlayers, { method: 'GET' })
    const progressions = yield call(request, urlProgressions, { method: 'GET' })
    const sequencesRaw = yield call(request, urlSequences, { method: 'GET' })

    const sequences = {}
    sequencesRaw.forEach(seq => {
      sequences[seq.id] = seq
    })

    let players = {}
    playersRaw.forEach(player => {
      players[player.id.toString()] = player
      players[player.id.toString()].sequenceCount = sequencesRaw.length // FIXME when journey arrive
      players[player.id.toString()].sequenceDoneCount = 0
    })

    progressions.forEach(p => {
      const id = p.playerId
      if (players[id] == null) {
        return
      }
      if (players[id].scoreDetails == null) { players[id].scoreDetails = [] }
      players[id].scoreDetails.push({
        title: sequences[p.sequenceId],
        id: p.sequenceId,
        isDone: p.sectionScores.reduce((a, v) => (v && v.score != null) ? a : false, true) // FIXME
      })
      players[id].sequenceDoneCount = players[id].scoreDetails.length
    })

    players = Object.keys(players).map(k => players[k])
    yield put(refreshPlayersSuccess(players))
  } catch (err) {
    yield put(refreshPlayersError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * refreshPlayersSaga () {
  yield takeLatest(REFRESH_PLAYERS, refreshPlayers)
}

export function * getToken ({ credentials }) {
  // JOURNEY PER DEFAULT, To Be Removed
  const journey = credentials.journey ? credentials.journey : 'parcours1'
  const getTokenUrl = `${serverPath}/api/players/gettoken?foreignKey=${credentials.foreignKey}&email=${credentials.email}&firstname=${credentials.firstname}&lastname=${credentials.lastname}&expirationDate=2030-01-01&access=premium&password=${credentials.password}&journey=${/* credentials.journey */journey}&access_token=${credentials.token}`

  try {
    // Call our request helper (see 'utils/request')
    const { token } = yield call(request, getTokenUrl, { method: 'GET' })
    yield put(getTokenSuccess(token))
  } catch (err) {
    yield put(getTokenError(err))
  }
}

export function * getTokenSaga () {
  yield takeLatest(GET_TOKEN, getToken)
}
