import { call, put, takeLatest } from 'redux-saga/effects'
import { PLAYER_EDIT, DELETE_PLAYER } from '../App/constants'
import {
  playerEditSuccess,
  playerEditError,
  deletePlayerSuccess,
  deletePlayerError
} from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
// const serverPath = `https://ver-client.tgd-test.site`
/**
 * Github repos request/response handler
 */

export function * deletePlayer ({ token, id, agencyid }) {
  console.log('DELETE ME')
  // const url = `${serverPath}/getPlayers/${password}`
  const urlPlayerDelete = `${serverPath}/api/players/${id}?access_token=${token}`
  // FIXME use api/agencies?filter[include][players]=sequenceProgressions&filter[where][id]=${id}&access_token=${id}
  const urlPlayers = `${serverPath}/api/players?filter[where][agencyId]=${agencyid}&access_token=${token}`
  const urlProgressions = `${serverPath}/api/sequenceProgressions?access_token=${token}`
  const urlSequences = `${serverPath}/api/sequences?access_token=${token}`

  try {
    // Call our request helper (see 'utils/request')
    yield call(request, urlPlayerDelete, { method: 'DELETE' })
    const playersRaw = yield call(request, urlPlayers, { method: 'GET' })
    const progressions = yield call(request, urlProgressions, { method: 'GET' })
    const sequencesRaw = yield call(request, urlSequences, { method: 'GET' })

    const sequences = {}
    sequencesRaw.forEach(seq => {
      sequences[seq.id] = seq
    })

    let players = {}
    playersRaw.forEach(player => {
      players[player.id.toString()] = player
      players[player.id.toString()].sequenceCount = sequencesRaw.length // FIXME when journey arrive
      players[player.id.toString()].sequenceDoneCount = 0
    })

    progressions.forEach(p => {
      const id = p.playerId
      if (players[id] == null) {
        return
      }
      if (players[id].scoreDetails == null) { players[id].scoreDetails = [] }
      players[id].scoreDetails.push({
        title: sequences[p.sequenceId],
        id: p.sequenceId,
        isDone: p.sectionScores.reduce((a, v) => (v && v.score != null) ? a : false, true) // FIXME
      })
      players[id].sequenceDoneCount = players[id].scoreDetails.length
    })

    players = Object.keys(players).map(k => players[k])
    yield put(deletePlayerSuccess(/* players */))
  } catch (err) {
    yield put(deletePlayerError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * deletePlayerSaga () {
  yield takeLatest(DELETE_PLAYER, deletePlayer)
}

export function * playerEdit ({ credentials }) {
  const editPlayersUrl = `${serverPath}/api/players/editPlayer?id=${credentials.id}&firstname=${credentials.firstname}&lastname=${credentials.lastname}&email=${credentials.email}${credentials.agencyid ? `&agencyId=${credentials.agencyid}` : ''}${credentials.password ? `&password=${credentials.password}` : ''}&access_token=${credentials.token}`
  const getPlayerUrl = `${serverPath}/api/players/${credentials.id}?access_token=${credentials.token}`
  try {
    yield call(request, editPlayersUrl, { method: 'PATCH' })
    const player = yield call(request, getPlayerUrl, { method: 'GET' })
    yield put(playerEditSuccess(player))
  } catch (err) {
    console.log(err)
    yield put(playerEditError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * playerEditData () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(PLAYER_EDIT, playerEdit)
}
