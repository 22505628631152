import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import PropTypes from 'prop-types'

const ProgressContainer = styled.div`
width:93%;
height:1rem;
border:1px solid #EFF0F6;
border-radius:0.375rem;
background-color:#EFF0F6;
margin-top: 6px;

`
const Filler = styled.div`
    width: ${props => props.percentage}%;
    height:100%;
    background:linear-gradient(90deg,#1AA0FF 0%, #166DD8 95.42%);
    border-radius:0.375rem;
`

const ProgressBar = (props) => {
  return (
    <ProgressContainer>
      <Filler percentage={props.percentage} />
    </ProgressContainer>
  )
}

ProgressBar.propTypes = {
  percentage: PropTypes.number
}
export default ProgressBar
