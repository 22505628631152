import React from 'react'
import {Bg, Container, Description, Title, TextContent, Name, Logo, InputTitle, InputField, ContentButton, ButtonAction, ButtonCancel, InputFieldDate} from './styledComponents'
import boyLogo from '../../images/boy.svg'
import moment from 'moment'

const ModalArchive = (props) => {
    if (!props.show) {
        return (<div />)
    }
    
    return (
        <Bg>
            <Container>
                <TextContent>
                    <Title>Clôturer un compte utilisateur</Title>
                    <Description>Vous êtes sur le point de fermer le compte de </Description>
                    <Name>
                        <Logo src={boyLogo} />
                        {props.name}
                    </Name>
                    <InputTitle>Heures de conduites réelles :</InputTitle>
                    <InputField onChange={(e) => {props.actualDrivingTotal(e.target.value)}} />
                    <InputTitle>Date d’obtention du permis  :</InputTitle>
                    <InputFieldDate type='date' onChange={(e) => {props.licenceDate(e.target.value)}} defaultValue={moment(Date.now()).format('DD/MM/YYYY')} placeholder={moment(Date.now()).format('DD/MM/YYYY')}/>
                    <InputTitle>Nombre de présentation à l’examen :</InputTitle>
                    <InputField onChange={(e) => {props.licenceAttempts(e.target.value)}} />
                </TextContent>
                <ContentButton>
                    <ButtonCancel onClick={() => props.cancel()}>Annuler</ButtonCancel>
                    <ButtonAction
                        disabled={!props.enableButton}
                        style={{ backgroundColor: props.enableButton ? '#00C4F3' : '#EFF0F6', color: props.enableButton ? 'black' : '#868383', cursor: props.enableButton ? 'pointer' : 'unset' }}
                        onClick={() => props.confirm()}>Clôturer
                    </ButtonAction>
                </ContentButton>
            </Container>
        </Bg>
    )
}

export default ModalArchive