import React from 'react'
import {Button, ButtonText} from './styledComponents'
import push from '../../images/push-down.svg'
import done from '../../images/Valide.png'
import { useState } from 'react'

const IOButtonSendData = ({id, accessToken}) => {
    const axios = require('axios')
    const [sendData, setSendData] = useState(false)

    const sendStudentStats = async () => {

        let evalToSend
        let progToSend
        
        try {
            const evalResponse = await axios.get(`${process.env.REACT_APP_TGDAPI_SERVER}/evals?studentId=${id}`, {headers: {Authorization: `Bearer ${accessToken}`}})
            if (evalResponse.data && evalResponse.data.length > 0) {
                evalToSend = evalResponse.data[0]
            }

            const progressionResponse = await axios.get(`${process.env.REACT_APP_TGDAPI_SERVER}/progressions?studentId=${id}`, {headers: {Authorization: `Bearer ${accessToken}`}})
            if (progressionResponse.data && progressionResponse.data.length > 0) {
                progToSend = progressionResponse.data[0]
            }

        } catch (error) {
            console.log(error)
        }
         console.log(evalToSend, progToSend)
    
         if (evalToSend || progToSend) {
          setSendData(true)
         }
         // Then send it to tgdapi plugin to format the content and send the result to IO.
      }

      return (
        <Button
        isDone={sendData}
        style={{
          cursor: sendData ? 'default' : 'pointer',
          color: 'black'
        }}
        disabled = {sendData}
        data-type='premium'
        onClick={() => sendStudentStats()}
      >
        <ButtonText>
          {sendData ? 'Données envoyées' : 'Récupération des données'}
        </ButtonText>
        <img alt='' style={{height: sendData ? '10px' : '16px', filter: 'invert(0.4)'}} src={sendData ? done : push} />
      </Button>
      )
}

export default IOButtonSendData