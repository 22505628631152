const strize = require('../strize')

module.exports = {
  title: 'ML ADMIN',
  imgs: {
    root: 'imagesMissionLocale'
  },
  home: '/simuplayers',
  features: {
    elearning: false,
    simu: true,
    eval: true
  },
  components: {
  },
  bucket: {
    player: {
      bucket: strize('elearningmarqueblanche'),
      region: strize('eu-west-3')
    },
    editor: {
      bucket: strize(''),
      region: strize(''),
      key: strize(''),
      secret: strize('')
    }
  },
  colors: {
    main: '#000000',
    bgSideBar: '#024179',
    ActionMail: '#dadada'
  },
  layouts: {
    login: {
      bottomLogo: 'simuLogo.png'
    },
    sidebar: {
      imgLogo: 'MLLOGO.png'
    },
    agencyCreate: {
      logo: false
    }
  }
}
