import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getInjectors from './sagaInjectors';
import { getStore } from '../../configureStore'
import { useEffect, useRef } from 'react';

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
 * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
 *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
 *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 *
 */
const InjectSaga =  ({ key, saga, mode }) => (WrappedComponent) => {
  const InjectSaga = (props) => {
    InjectSaga.WrappedComponent = WrappedComponent;
    InjectSaga.contextTypes = {
      store: PropTypes.object,
    };
    InjectSaga.displayName = `withSaga(${(WrappedComponent.displayName || WrappedComponent.name || 'Component')})`;
    // TODO: Comment & doc
    const useWillMount = (fn) => {
      const willMount = useRef(true)
      if (willMount.current && fn && typeof fn === 'function') {
        fn()
      }
      willMount.current = false
    }

    useWillMount(() => {
      const injectors = getInjectors(getStore())
      const { injectSaga } = injectors
      injectSaga(key, { saga, mode }, props)
    })

    useEffect(() => {
      return () => {
        const injectors = getInjectors(getStore())
        const { ejectSaga } = injectors
        ejectSaga(key)
      }
    }, [])


      return <WrappedComponent {...props} />
  }

  return hoistNonReactStatics(InjectSaga, WrappedComponent);
};

export default InjectSaga

