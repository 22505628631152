
import React from 'react'

const ShouldRefresh = (props) => {
  if (props.refresh === true) {
    return (<div>{props.refreshPlayers(props.token, props.agencyid, props.isAdmin)}</div>)
  }
  return (null)
}

export default ShouldRefresh
