/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux'
import Immutable, { fromJS } from 'immutable'
import { routerMiddleware } from 'connected-react-router/immutable'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './reducers'
import { loadState, saveState } from './localStorage'
import { createBrowserHistory } from 'history'

const sagaMiddleware = createSagaMiddleware()
let localStore = null

export const history = createBrowserHistory()

export default function configureStore (initialState = Immutable.Map()) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history)
  ]

  const enhancers = [
    applyMiddleware(...middlewares)
  ]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
        // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false
        })
      : compose

  const persistedState = loadState()
  const store = createStore(
    createRootReducer(history),
    fromJS(persistedState),
    composeEnhancers(...enhancers)
  )

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.injectedReducers = {} // Reducer registry
  store.injectedSagas = {} // Saga registry

  store.subscribe(() => {
    saveState({
      global: {
        agencytoken: store.getState().get('global').get('agencytoken'),
        remotetoken: store.getState().get('global').get('remotetoken'),
        agencyTokenTgdApi: store.getState().get('global').get('agencyTokenTgdApi'),
        agencyid: store.getState().get('global').get('agencyid'),
        isAdmin: store.getState().get('global').get('isAdmin'),
        evalScale: store.getState().get('global').get('evalScale'),
        lang: store.getState().get('global').get('lang') || 'fr', // Add lodash.throttle ?
        simuPlayers: store.getState().get('global').get('simuPlayers'),
        players: store.getState().get('global').get('players'),
        autorisation: store.getState().get('global').get('autorisation'),
      }
    })
  })
  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createRootReducer(history))
    })
  }
  localStore = store
  return store
}

export function getStore () {
  return localStore
}
