import React, { useState } from 'react'
import styled from 'styled-components'
import Uieye from '../../images/Uieye.svg'
import Uicloseeye from '../../images/eye-alt.svg'
import IconPassword from '../../components/InputPasswordImage'
import { Redirect } from 'react-router-dom'
import '../../fonts/fonts.css'
import config from '../../config/configs'

const InputHideAndShow = styled.input`
display: block;
border-radius: 8px;
background-color: #ffffff;
border: solid 2px #dadada;
width: 16.87rem;
height: 2.3rem;
box-sizing: border-box;
margin: auto;
margin-bottom: 0.6rem;
padding-left: 1rem;
font-size: 1rem;
&:focus {
  outline: none;
  border: 3px solid #00c4f3;
}
`

const IconImage = styled.div`
width:1rem;
height:1rem;
color:white;
cursor:pointer;
position:relative;
top:-2.2rem;
left:14.8rem;
@media(max-width:1536px){
  left:14.8rem;
}
`

const Inputs = ({ onChange, islogged }) => {
  const [type, setType] = useState(true)
  const [value, setValue] = useState('')

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      // this.props.login()
    }
  }

  const onValueChange = (value) => {
    setValue(value)
    onChange(value)
  }

  if (islogged === 'connected') {
    return (<Redirect to={config.features.simu ? '/simuplayers' : '/players'} />)
  } else {
    return (
      <>
        <InputHideAndShow onKeyPress={() => onKeyPress} value={value} type={type ? 'password' : 'text'} onChange={(e) => onValueChange(e.target.value)} />
        <IconImage onClick={() => setType(!type)}>
          <IconPassword icon={type === true ? Uicloseeye : Uieye} />
        </IconImage>
      </>
    )
  }
}

export default Inputs
