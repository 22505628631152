/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { getAgenciesSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { getAgencies } from '../App/actions'

const AgenciesLoader = () => {
  const dispatch = useDispatch()

  const agencies = useSelector((state) => state.toJS().global.agencies)
  const token = useSelector((state) => state.toJS().global.agencytoken)
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)

  useEffect(() => {
    if (isAdmin && agencies == null && token != null) {
      dispatch(getAgencies(token))
    }
    // eslint-disable-next-line
  }, [token])

  return <div />
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'AgenciesLoader', saga: getAgenciesSaga })
const arg = compose(withSaga, withConnect)(AgenciesLoader)

export default arg
