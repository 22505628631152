import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Icon = styled.img`
  height: 1rem;
  width:1rem;
  margin-right: 1rem;
`

const ContainerActive = styled.button`
width: 80%;
height: 12%;
font-family: Poppins;
white-space: nowrap;
font-size: 100%;
margin-top:6%;
text-align: center;
font-weight:400;
cursor: pointer;
border: none;
background-color: unset;
margin-bottom:2%;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
&:hover{color:#00C4F3;}
&:hover ${Icon} {
  filter: invert(57%) sepia(84%) saturate(1307%) hue-rotate(148deg) brightness(100%) contrast(120%);
}
&:focus{outline:none;}
`
const ContainerPassive = styled.button`
width: 80%;
height: 12%;
font-family: Poppins;
white-space: nowrap;
font-size: 100%;
margin-top:6%;
text-align: center;
font-weight:400;
cursor: pointer;
border: none;
background-color: unset;
margin-bottom:2%;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
color: white;
&:hover{color:#00C4F3;}
&:hover ${Icon} {
  filter: invert(57%) sepia(84%) saturate(1307%) hue-rotate(148deg) brightness(100%) contrast(120%);
}
&:focus{outline:none;}
`

export function Button ({ to, active, onClick, icon, text }) {
  if (to == null) {
    return (
      <ContainerActive onClick={onClick}>
        {icon ? <Icon src={icon} /> : null}
        {text}
      </ContainerActive>
    )
  }

  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      {active
        ? <ContainerActive style={{ color: active ? '#00C4F3' : 'white' }} onClick={onClick}>
          {icon ? <Icon src={icon} /> : null}
          {text}
        </ContainerActive>
        : <ContainerPassive onClick={onClick}>
          {icon ? <Icon src={icon} /> : null}
          {text}
        </ContainerPassive>}
    </Link>
  )
}

export default Button
