import styled from 'styled-components'
import UiCar from '../../images/car.svg'
import Delete from '../../images/delete.svg'
import Select from 'react-select'

export const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

export const MainLayout = styled.div`
  float: right;
  flex-direction: column;
  width: 79.88%;
  justify-content: center;
  background-color: white;
`

export const Container = styled.div`
  width: 100%;
`

export const FormAndLogoContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 80px;
  margin-top: -20px;
`

export const FormContainer = styled.div`
  position: relative;
  width: 550px;
`
export const UploadContainer = styled.div`
  height: 100%;
  margin-left: 5rem;
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

export const Name = styled.div`
  font-family: Poppins;
  font-weight: 300;
  font-size: 0.75rem;
  font-style: normal;
  color: #4f4f4f;
  line-height: 18px;
  margin-left: 1rem;
`

export const Input = styled.input`
  border-radius: 10px;
  background-color: white;
  border: 1px solid #b3abbc;
  height: 2.37rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  width: 96%;
  &:focus {
    outline: none;
  }
  &:focus {
    box-shadow: 0px 0px 3px 3px #00c4f3;
  }
`

export const FeedBack = styled.div`
  color: red;
  margin-left: 1rem;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.7rem;
  margin-top: -0.5rem;
  position: absolute;
  bottom: 10px;
`

export const DeleteInput = styled.img`
  position: absolute;
  z-index: 10;
  height: 0.6rem;
  widht: 0.6rem;
  &:hover {
    cursor: pointer;
  }
`

export const UploadInput = styled.div`
  border-left: solid 1px black;
  padding: 10px;
  margin-left: 5rem;
  display: flex;
  align-items: center;
`

export const Button = styled.button`
  width: 10.4rem;
  height: 3rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  color: #868383;
  border-radius: 10px;
  background-color: #eff0f6;
  border: unset;
`

export const DeleteButton = styled.button`
  width: 8.8rem;
  height: 3rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  color: #868383;
  padding-left: 5%;
  cursor: pointer;
  border-radius: 10px;
  background-color: #eff0f6;
  background-image: url(${Delete});
  background-repeat: no-repeat;
  background-position: bottom 50% left 12%;
  box-sizing: border-box;
  border: unset;
`

export const UploadTitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #808080;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`

export const Success = styled.div`
  position: absolute;
  z-index: 10;
  top: 12rem;
  left: 20rem;
  height: 10rem;
  width: 15rem;
  border-radius: 0.5rem;
  text-align: center;
  background-color: #fffcd3;
  font-family: Poppins;
`
export const TextSuccess = styled.div`
  margin-top: 1rem;
  font-size: 1.1rem;
`
export const ImgSuccess = styled.img`
  margin-top: 1rem;
  height: 4rem;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const SelectorContainer = styled.div`
  width:100%;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  padding-top: 100px;
`

export const SelectorEvalContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
`

export const Selector = styled(Select)`
.react-select__control {
  border-radius: 10px;
  width: 34rem;
  color:black;
  font-family:Poppins;
  font-size:13px;
  outline: none;
  border: 1px solid #B3ABBC;
  height: 2.75rem;
  cursor: pointer;
  padding-left: 2.25rem;
  background-image: url(${UiCar});
  background-size: 1.1rem;
  background-position: top 50% left 1rem;
  background-repeat: no-repeat;
}
.react-select__option {
  font-family: Poppins;
  font-size: 13px;
  cursor:  pointer;
}
.react-select__indicator-separator {
  display: none;
}
.react-select__dropdown-indicator {
  color: black;
}
  & .react-select__control--is-focused {box-shadow: 0px 0px 3px 3px #00C4F3;}
`

export const SelectorScaleEval = styled.select`
  border-radius: 10px;
  background-color: white;
  border: 1px solid #B3ABBC;
  outline: none;
  height: 2.37rem;
  width: 33.125rem;
  padding-left: 0.7rem;
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
  background-size: 1.1rem;
  background-position: top 50% left 4%;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 8px;
`

export const ArrowDown = styled.img`
width: 0.8rem;
position: absolute;
margin-top: 15px;
margin-left: 12.5%;
z-index: 10;
`
export const Opt = styled.option`
  padding-left: 3rem;
`
