
/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import './GridOverload.css'
import '../../fonts/fonts.css'
import ModalConfirm from '../../components/ModalConfirm'
import { deleteAgencySaga, refreshAgenciesSaga, getDashboardSaga, getFilteredAgenciesSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { deleteAgency, refreshAgencies, getDashboard, getFilteredAgencies } from '../App/actions'
import ShouldRefreshAgencies from '../ShouldRefreshAgencies/index'
import AgencyAvatar from '../../images/firstAgency.svg'
import AddStudent from '../../images/Add.svg'
import config from '../../config/configurator'
import { FilterContainer, ButtonContainer, ValidateButtonContainer, ResetButtonContainer,  InputContainer, Input, IconImg, HeaderContainer, ActionContainer, FilterSubContainer, DateFilter, ActionEdit, ActionArchive, Loader, DeleteIcon, EditIcon, Bg, FirstContainer, ContainerFirstAgency, TextFirstCSV, AvatarContainer, ButtonImport, ButtonCreate, ImportImg, ImportText, LayoutContainer } from './styledComponents'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import UiEdit from '../../images/edit.svg'
import UiDelRed from '../../images/closeRed.svg'
import Dashboard from '../../components/Dashboard'
import SearchIcon from '../../images/search.svg'
import ResetIcon from '../../images/delete.svg'

// import ArrowFilter from '../../images/arrow.svg'

const Agency = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const agencies = useSelector((state) => state.toJS().global.agencies)
  console.log('agencies',agencies)
  const token = useSelector((state) => state.toJS().global.agencytoken)
  const players = useSelector((state) => state.toJS().global.players)
  const simuPlayers = useSelector((state) => state.toJS().global.simuPlayers)
  const isloading = useSelector((state) => state.toJS().global.loading)
  //const error = useSelector((state) => state.toJS().global.error)
  let numOfStudents = 0
  // const [showPanel, setShowPanel] = useState() // FIXME: use react-tooltip ?
  const [currentAgencyId, setCurrentAgencyId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState('')
  const [modalName, setModalName] = useState('')
  const [filters, setFilters] = useState({agency: '', date: ''})
  const [activatedButton, setActivatedButton] = useState(false)
  const [activatedColor, setActivatedColor] = useState(false)

  useEffect(() => {
    dispatch(getDashboard(token))
    dispatch(refreshAgencies(token))
    // eslint-disable-next-line
  }, [])

  function confirmDelete (id, action, agency) {
    setShowModal(true)
    setModalText(`${action}`)
    setModalName(agency)
    setCurrentAgencyId(id)
    console.log(id, agency)
  }

  const deleteCurrAgency = (id) => {
    dispatch(deleteAgency(token, id))
    setTimeout(() => {
      history.go(0)
    }, 500)
  }

  const buildRows = () => {
    const rowData = []
    if (isloading) { return null }
    agencies.forEach((agency) => {
      const agencyPlayers = config.features.simu ? simuPlayers.reduce((a, v) => (v.agencyId === agency._id ? a + 1 : a), 0) : players.reduce((a, v) => (v.agencyId === agency._id ? a + 1 : a), 0)
      numOfStudents = numOfStudents + agencyPlayers
      rowData.push({
        agency: agency.agencyname || agency.agencyName,
        agencyId: agency.agencyId || agency._id,
        players: agencyPlayers,
        creationDate: moment(agency.dateCreation).format('DD/MM/YYYY')
      })
    })
    return rowData
  }

  const filterdate = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue
      if (dateAsString == null) return -1
      var dateParts = dateAsString.split('/')
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      )
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1
      }
    },
    browserDatePicker: true,
  }

  // const sortableIcons = {
  //   sortAscending: `<img src=${ArrowFilter} style="height: 16px; width: 12px; position: absolute; right: 16px; top: 15px; transform: rotate(180deg);"/>`,
  //   sortDescending: `<img src=${ArrowFilter} style="height: 16px; width: 12px; position: absolute; right: 16px; top: 15px;"/>`,
  //   sortUnSort: `<img src=${ArrowFilter} style="height: 16px; width: 12px; position: absolute; right: 16px; top: 15px; transform: rotate(90deg);"/>`
  // }

  const gridOptions = {
    columnDefs: [
      { width: 253,suppressMovable: true, headerName: 'Agences', field: 'agency', cellClass:'rowGreyBackgroundwithAgencyImg', floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true }, cellRendererFramework: (params) => { return <Link style={{lineHeight:'1.5rem',paddingLeft:'3rem',textOverflow: 'ellipsis', overflow: 'hidden',}} to={{ pathname: `agency/edit/${params.data.agencyId}`, query: { id: params.data.agencyId } }}>{params.data.agency}</Link> } },
      // { width: 145, suppressMovable: true, headerName: 'Eleves', field: 'players', cellClass:'rowGreyBackground', suppressMenu: true, sortable: true, unSortIcon: true, icons: sortableIcons, floatingFilterComponentParams: { suppressFilterButton: true } },
      { width: 195, suppressMovable: true, headerName: 'Date', field: 'creationDate', cellClass:'rowGreyBackground', suppressMenu: true, filter: 'agDateColumnFilter', filterParams: filterdate, floatingFilterComponentParams: { suppressFilterButton: true } },
      {
        suppressMovable: true,
        width: 88,
        suppressMenu: true,
        cellClass:'rowGreyBackground',
        field: 'Actions',
        cellRendererFramework: (params) => {
          return (
            <ActionContainer>
              <Link to={`agency/edit/${params.data.agencyId}`}>
                <ActionEdit>
                  <EditIcon src={UiEdit} />
                </ActionEdit>
              </Link>
              <ActionArchive onClick={() => confirmDelete(params.data.agencyId, 'Attention, vous allez supprimer définitivement l\'agence ', params.data.agency)}>
                <DeleteIcon
                  src={UiDelRed}
                />
              </ActionArchive>
            </ActionContainer>
          )
        }
      }
    ]
  }

  const handleClickFilters = (e, type) => {
    const updatedFilters = { ...filters }
  
    if (type === 'agency') {
      updatedFilters.agency = e.target.value
    } else if (type === 'date') {
      updatedFilters.date = e.target.value
    }
  
    setActivatedButton(false)
    setFilters(updatedFilters)
    setActivatedColor(true)
  }

  const handleClickFindAgencies = () => {
    dispatch(getFilteredAgencies(filters, agencies, token))
    const hasFilters = filters.agency || filters.date
    const isAllSelected = filters.agency === 'all' || filters.status === 'all' || filters.licence === 'all'
    setActivatedColor(hasFilters && !isAllSelected)
    setActivatedButton(hasFilters && !isAllSelected)
  }

  const firstAgency = () => {
    return (
    <FirstContainer>
    <ContainerFirstAgency>
      <AvatarContainer>
        <img alt='' src={AgencyAvatar} />
      </AvatarContainer>
      <TextFirstCSV>
        Vous pouvez <b>créer une agence</b> avec votre console de gestion
      </TextFirstCSV>
      <ButtonImport>
        <ButtonCreate to='/agency/create' style={{ width: '201px' }}>
          <ImportImg src={AddStudent} />
          <ImportText>Créer une agence</ImportText>
        </ButtonCreate>
      </ButtonImport>
    </ContainerFirstAgency>
  </FirstContainer>
  )
  }

  if ((agencies && (players || simuPlayers)) == null) {
    return <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center', marginTop:'25%',fontSize:'2rem', flexDirection:'column'}}>
    <Loader></Loader>
  </div>
  }

  if (isloading === true) {
    return <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center', marginTop:'25%',fontSize:'2rem', flexDirection:'column'}}>
    <Loader></Loader>
  </div>
  }
  //const isError = !error
  return (
    <Bg>
      <ShouldRefreshAgencies token={token} />
      <ModalConfirm show={showModal} name={modalName} text={modalText} confirmText='Supprimer' confirm={() => { deleteCurrAgency(currentAgencyId); setShowModal(false) }} cancel={() => setShowModal(false)} />
      {!isloading
        ?
          <LayoutContainer className='agencygrid'>
            <HeaderContainer>
              <Dashboard height={'56px'} type='agencies' />
            </HeaderContainer>
            <div className='ag-theme-alpine-agency' style={{ height: 610, width: 540, display: 'flex', justifyContent: 'center', flexDirection: 'column', position: 'relative' }}>
            <FilterContainer>
              <FilterSubContainer style={{backgroundColor: activatedButton ? '#DFF7E6' : '#eff0f6'}}>
                <InputContainer>
                  <IconImg src={SearchIcon} />
                  <Input onChange={(e) => handleClickFilters(e, 'agency')} value={filters.agency || ''} placeholder='Chercher une agence' />
                </InputContainer>
                <DateFilter value={filters.date || ''} type="date" onChange={e =>  handleClickFilters(e, 'date')} />
                <ButtonContainer>
                  <ValidateButtonContainer 
                    onClick={() => handleClickFindAgencies()}
                    style={{display: activatedColor ? 'flex' : 'none'}}>
                    <IconImg style={{paddingRight: '4px'}} src={SearchIcon} />
                  </ValidateButtonContainer>
                  <ResetButtonContainer 
                    onClick={() => {
                      setFilters({agency: '',date: ''})
                      dispatch(refreshAgencies(token))
                      setActivatedButton(false)
                      setActivatedColor(false)
                    }}
                    style={{display: activatedColor ? 'flex' : 'none'}}>
                    <IconImg style={{paddingRight: '4px'}} src={ResetIcon} />
                  </ResetButtonContainer>
                </ButtonContainer>
              </FilterSubContainer>
            </FilterContainer>
              <AgGridReact
                rowData={buildRows()}
                gridOptions={gridOptions}
                rowHeight={55}
                overlayNoRowsTemplate={'Aucune agence trouvée'}
              />
            </div>
          </LayoutContainer>
        :
        firstAgency()
        }
    </Bg>
  )
}

const withConnect = connect(null, null)
const withDelete = injectSaga({ key: 'deleteAgency', saga: deleteAgencySaga })
const withSagaRefreshAg = injectSaga({ key: 'refreshAgencies', saga: refreshAgenciesSaga })
const withDashboard = injectSaga({ key: 'getDashboard', saga: getDashboardSaga })
const withFilteredAgencies = injectSaga({ key: 'getFilteredAgencies', saga: getFilteredAgenciesSaga })
const arg = compose(withDelete, withSagaRefreshAg, withDashboard, withFilteredAgencies, withConnect)(Agency)

export default arg
