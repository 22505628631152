import AWS from 'aws-sdk'

AWS.config.credentials = {
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID_EDITOR,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_EDITOR,
  region: 'eu-west-3'
}

// # # # # # # # # # # # # #  copies file from a place to another, it can be different bucket # # # # # # # #
export const awsCopy = (bucketSource, fileSource, bucketDest, fileDest) => {
// note : fileSource and BucketSource include the folders
  const s3 = new AWS.S3()
  const paramscopy = {
    Bucket: bucketDest,
    CopySource: bucketSource + '/' + fileSource,
    Key: fileDest
  }
  return new Promise((resolve, reject) => {
    s3.copyObject(paramscopy, function (err, data) {
      if (err) {
        console.log('s3 copy file erreur')
        resolve(false)
      } else {
        console.log('copy ok')
        resolve(true)
      }
    })
  })
}

// # # # # # # # # # # # # #  this one copies but gives a few trys before giving up # # # # # # # #

export const awsCopyRetry = async (bucketSource, fileSource, bucketDest, fileDest) => {
  let count = 1
  let copystatus = false
  return new Promise(async(resolve, reject) => {
    while (!copystatus && count < 8) {
      const tryCopy = await awsCopy(bucketSource, fileSource, bucketDest, fileDest)
      if (tryCopy) {
        copystatus = true
      } else {
        console.log('retry copy', count)
        // this is a sleep function
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve()
          }, 3000)
        })
        count++
      }
    }
    if (copystatus) {
      resolve(count)
    } else {
      resolve(false)
    }
  })
}

// # # # # # # # # # # # # # delete a file # # # # # # # #
// note : returns true if it worked. false if there was an error

export const awsDelete = async (bucket, file) => {
  const s3 = new AWS.S3()
  const deleteParams = {
    Bucket: bucket,
    Delete: {
      Objects: [{ Key: file }]
    }
  }
  return new Promise((resolve, reject) => {
    s3.deleteObjects(deleteParams, (err, data) => {
      if (err) {
        console.log('delete error', err)
        resolve(false)
      } else {
        console.log('delete OK', data)
        resolve(true)
      }
    })
  })
}
