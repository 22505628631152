import styled from 'styled-components'
import { Link } from 'react-router-dom'
import config from '../../config/configurator'
import Select from 'react-select'
import datePickerIcon from '../../images/datePickerIcon.svg'

export const HeaderContainer = styled.div`
  align-items: center;
  width: 1215px;
  margin: 1rem 0 1rem 0;
`

export const GridHeaderContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 32.5px;
`

export const DashboardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FilterContainer = styled.div`
  align-items: center;
`

export const ButtonContainer = styled.div`
  position: absolute;
  right: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 31px;
  width: 80px;
`

export const ResetButtonContainer = styled.div`
  display: flex;
  height: 30.72px;
  width: 30.72px;
  border: 1px solid ligtgray;
  border-radius: 5px;
  background-color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: #AAE5F3;
  }
`

export const ValidateButtonContainer = styled.div`
  display: flex;
  height: 30.72px;
  width: 30.72px;
  border: 1px solid ligtgray;
  border-radius: 5px;
  background-color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: #AAE5F3;
  }
`

export const FilterSubContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 90%;
  height: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #eff0f6;
  border-radius: 5px;
`

export const InputContainer = styled.div`
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  background-color: white;
  margin-left: 5px;
  position: relative;
`

export const Input = styled.input`
  height: 100%;
  display: flex;
  font-family:Poppins;
  color: black;
  width: 100%;
  padding-left: 26px;
  font-size: 13px;
  border: 1px solid lightgray;
  box-sizing: content-box;
  border-radius: 5px;
  outline-color: #2684FF;
  cursor: pointer;
  &:focus {
    box-sizing: content-box;
    border-radius: 5px;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: black;
  }
  :-ms-input-placeholder {
     color: black;
  
`

export const IconImg = styled.img`
  height: 0.7vw;
  padding-left: 5px;
  position: absolute;
  left: 5px;
`

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10%;
  font-family:Poppins;
  margin-right: 12px;
`

export const LayoutContainer = styled.div`
  height:100%;
  width:70vw;
  margin-left:3rem;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  position: relative;
`
export const ActionContainer = styled.div`
  display: flex;
  align-items: center;  
  justify-content: center;
  height: 3rem;
`
export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  width: 5rem;
  justify-content: space-around;
`

export const Container = styled.div`
  margin-top: 2%;
`
export const Loader = styled.div`
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #024179;
  width: 3rem;
  height: 3rem;
  margin-top:1rem;
  font-family:Poppins;
  animation: spin 1s linear infinite;

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`

export const Action = styled.div`
  display: flex;
  top:20%;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  height: 1.6vw;
  width: 1.6vw;
  border: 1px solid black;
  border-radius: 0.225rem;
  cursor: pointer;
  &:hover {
    background-color: #AAE5F3;
  }
  border: ${config.colors.ActionMail};
  background-color: white;
`

export const InactiveAction = styled.div`
  display: flex;
  top:20%;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  height: 1.6vw;
  width: 1.6vw;
  border: 1px solid black;
  border-radius: 0.225rem;
  border: ${config.colors.ActionMail};
  background-color: white;
`

export const ActionEdit = styled(Action)``

export const ActionDelete = styled(Action)``

export const ActionTransfer = styled.button`
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`

export const ActionArchive = styled(Action)``

export const EditIcon = styled.img`
  height: 0.8vw;
  object-fit: content;
`

export const ArrowPageRight = styled.img`
  height: 0.8vw;
  object-fit: content;
  transform: rotate(-90deg);
`

export const ArrowPageLeft = styled.img`
  height: 0.8vw;
  object-fit: content;
  transform: rotate(90deg);
`

export const DeleteIcon = styled.img`
  height: 0.7vw;
`
export const ArchiveIcon = styled.img`
  height: 0.7vw;
`

export const Bg = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-x:hidden;
`

export const Progression = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  height: 55px;
`

export const ProgressionBar = styled.div`
  margin-left: -0.7rem;
  width: 80%;
  height: 1.3rem;
  background: white; 
  border-radius: 0.3rem;
  margin-top: -0.4rem;
`

export const ProgressionBarIn = styled.div`
  height: 100%;
  border-radius: 0.3rem;
  background: linear-gradient(90deg, #1AA0FF 0%, #166DD8 95.42%);
`

export const ProgressionText = styled.div`
  width: 25%;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 10px;
`

export const HoverPanel = styled.div`
  position: absolute;
  color: white;
   height: 1.5rem;
   padding-left: 0.3rem;
   padding-right: 0.3rem;
   padding-top: 0.3rem;
   font-size: 0.6rem;
   font-family: Poppins;
   font-weight: 400;
   background-color: #4F4F4F;
   border-radius: 0.3rem;
   z-index: 10;
`

export const TopBarContainer = styled.div`
  height: 3.5rem;
  display: flex;
  align-items: center;
  position: relative;
`

export const TopBar = styled.div`
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.1rem;
`

export const EvalBar = styled.div`
  border-bottom: solid #53AD49 0.3rem;
  width: 214px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.1rem;
`

export const SimulBar = styled.div`
  border-bottom: solid #297FCC 0.3rem;
`

export const SelectorContainer = styled.div`
  height: 2.7rem;
  display: flex;
  align-items: center;
`

export const FirstContainer = styled.div`
  width: 80vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerFirstStudent = styled.div`
  width: 377px;
  height: 322px;
  display: flex;
  flex-direction: column;
  border: 1px solid #9C9C9D;
  box-sizing: border-box;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`

export const AvatarContainer = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const TextFirstCSV = styled.p`
  text-align: center;
  margin: 10px auto;
  margin-top: 1.3rem;
  width: 85%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
`

export const ButtonImport = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
`

export const ButtonTransfer = styled(Link)`
&:hover {
  background-color: #AAE5F3;
}
`

export const ImportImg = styled.img`
  width:1rem;
  height:1rem;
  display: flex;
  position: absolute;
  left: 1rem;
`

export const ImportText = styled.div`
  font-size: 16px;
  color: black;
  width: 100%;
  position: absolute;
  left: 2rem;
  margin-left: 8px;
`

export const ButtonCreate = styled(Link)`
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #979797;
  background-color:#ffffff;
  color:black;
  font-family:Poppins;
  font-style:normal;
  font-size:1rem;
  margin-bottom:1rem;
  line-height:3rem;
  box-shadow: 0px 2px 7px rgba(0,0,0,0.14);
  position: absolute;
`

export const TransferButton = styled(Link)`
  text-decoration:none;
  font-size: 1rem;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
  height: 31px;
`

export const IconFilter = styled.img`
  margin-left: 1.125rem;
  margin-right: 0.75rem;
`
export const FilterText = styled.div`
  color: black;
  font-family: Poppins;
  font-size: 12px;
  line-height: 23.27px;
  display: flex;
  padding-right: 1.25rem;
`

export const LicenceSelector = styled(Select)`
.react-select__control {
  width: 7rem;
  color:black;
  font-family:Poppins;
  font-size:13px;
  height: 2rem;
  min-height: 2rem;
  cursor: pointer
}
.react-select__option {
  font-family: Poppins;
  font-size: 13px;
  cursor:  pointer;
}
.react-select__indicator-separator {
  display: none;
}
.react-select__dropdown-indicator {
  padding: 0px 5px 0px 0px;
  color: black;
  width: 20px;
}
`

export const StatusSelector = styled(Select)`
.react-select__control {
  width: 11.7rem;
  color:black;
  font-family:Poppins;
  font-size:13px;
  height: 2rem;
  min-height: 2rem;
  cursor: pointer
}
.react-select__option {
  font-family: Poppins;
  font-size: 13px;
  cursor:  pointer;
}
.react-select__indicator-separator {
  display: none;
}
.react-select__dropdown-indicator {
  padding: 0px 5px 0px 0px;
  color: black;
  width: 20px;
}
`

export const AgencySelector = styled(Select)`
.react-select__control {
  width: 12rem;
  color:black;
  font-family:Poppins;
  font-size:13px;
  height: 2rem;
  min-height: 2rem;
  cursor: pointer
}
.react-select__option {
  font-family: Poppins;
  font-size: 13px;
  cursor:  pointer;
}
.react-select__indicator-separator {
  display: none;
}
.react-select__dropdown-indicator {
  padding: 0px 5px 0px 0px;
  color: black;
  width: 20px;
}
`

export const DateFilter = styled.input`
  z-index: 1;
  width: 104px;
  height: 1.9rem;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  margin-left: -303px;
  cursor: pointer;
  font-family: Poppins;
  font-weight: 300;
  font-size: 11px;
  padding-left: 8px;
  &:focus {
    outline-color: #2684FF;
  }
  ::-webkit-calendar-picker-indicator {
    background: url(${datePickerIcon}) no-repeat;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 4px 2px 0px 0px;
}
`