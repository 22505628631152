
import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_TOKEN } from '../App/constants'
import { getTokenSuccess, getTokenError} from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
// const serverPath = `https://ver-client.tgd-test.site`

/**
 * Github repos request/response handler
 */
export function * getToken ({credentials}) {
  console.log(credentials)
  //JOURNEY PER DEFAULT, To Be Removed
  let journey = credentials.journey ? credentials.journey : "parcours1"
  console.log("JOURNEY", journey)
  const getTokenUrl = `${serverPath}/api/players/gettoken?foreignKey=${credentials.email}&email=${credentials.email}&firstname=${credentials.firstname}&lastname=${credentials.lastname}&expirationDate=2030-01-01&access=premium&password=${credentials.password}&journey=${/*credentials.journey*/journey}&access_token=${credentials.token}`

  try {
    // Call our request helper (see 'utils/request')
    let {token} = yield call(request, getTokenUrl, {method: 'GET'})
    yield put(getTokenSuccess(token))
  } catch (err) {
    yield put(getTokenError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * getTokenSaga () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_TOKEN, getToken)
}


