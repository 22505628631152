import styled from 'styled-components'
import { Link } from 'react-router-dom'
import config from '../../config/configurator'
import datePickerIcon from '../../images/datePickerIcon.svg'

export const GridContainer = styled.div`
  height: 90%;
  display: flex;
  justify-content: center;
  margin-top: 120px;
  position: relative;
`

export const ContentLayout = styled.div`
  display: inline;
  flex-direction: row;
  height: 100%;
  margin-left: -25px;
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  padding-top:0.25rem;
`
export const Container = styled.div`
  margin-top: 2%;
`
export const Loader = styled.div`
border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #024179;
  width: 3rem;
  height: 3rem;
  margin-top:1rem;
  font-family:Poppins;
  animation: spin 1s linear infinite;

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`

export const Action = styled.div`
  display: flex;
  top:20%;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  height: 1.6vw;
  width: 1.6vw;
  border-radius: 0.225rem;
  cursor: pointer;
  margin-left:1rem;
  margin-right:-0.5rem;
  &:hover {
    background-color: #AAE5F3;
  }
  border: ${config.colors.ActionMail};
  background-color: white;
`

export const ActionEdit = styled(Action)``

export const ActionDelete = styled(Action)``

export const ActionTransfer = styled.button`
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`

export const ActionArchive = styled(Action)``

export const EditIcon = styled.img`
height: 0.8vw;
object-fit: content;
`

export const DeleteIcon = styled.img`
  height: 0.7vw;
`
export const ArchiveIcon = styled.img`
  height: 0.7vw;
`

export const Bg = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-x:hidden;
  margin-left: -40px;
`

export const Progression = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  height: 55px;
`

export const ProgressionBar = styled.div`
  margin-left: -0.7rem;
  width: 80%;
  height: 1.3rem;
  background: white; 
  border-radius: 0.3rem;
  margin-top: -0.4rem;
`

export const ProgressionBarIn = styled.div`
  height: 100%;
  border-radius: 0.3rem;
  background: linear-gradient(90deg, #1AA0FF 0%, #166DD8 95.42%);
`

export const ProgressionText = styled.div`
  width: 25%;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 10px;
`

export const HoverPanel = styled.div`
  position: absolute;
  color: white;
  height: 1.5rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0.3rem;
  font-size: 0.6rem;
  font-family: Poppins;
  font-weight: 400;
  background-color: #4F4F4F;
  border-radius: 0.3rem;
  z-index: 10;
`

export const TopBar = styled.div`
  display: flex;
  width: 55%;
  padding-left: 7.7%;
  height: 5%;
  margin-top: 3.5%;
  margin-bottom:2%;
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.1rem;
`

export const EvalBar = styled.div`
  margin-left: 5%;
  border-bottom: solid #53AD49 0.3rem;
`

export const SimulBar = styled.div`
  border-bottom: solid #297FCC 0.3rem;
`

export const FirstContainer = styled.div`
  width: 80vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerFirstStudent = styled.div`
  width: 377px;
  height: 322px;
  display: flex;
  flex-direction: column;
  border: 1px solid #9C9C9D;
  box-sizing: border-box;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`

export const AvatarContainer = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const TextFirstCSV = styled.p`
  text-align: center;
  margin: 10px auto;
  margin-top: 3.3rem;
  width: 85%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
`

export const ButtonImport = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
`

export const ImportImg = styled.img`
  width:1rem;
  height:1rem;
  display: flex;
  position: absolute;
  left: 1rem;
`

export const ImportText = styled.div`
  font-size: 16px;
  color: black;
  width: 100%;
  position: absolute;
  left: 2rem;
  margin-left: 8px;
`

export const ButtonCreate = styled(Link)`
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #979797;
  background-color:#ffffff;
  color:black;
  font-family:Poppins;
  font-style:normal;
  font-size:1rem;
  margin-bottom:1rem;
  line-height:3rem;
  box-shadow: 0px 2px 7px rgba(0,0,0,0.14);
  position: absolute;
`

export const TransferButton = styled(Link)`
text-decoration:none;
font-size: 1rem;
`

export const DateFilter = styled.input`
  z-index: 1;
  width: 130px;
  height: 1.9rem;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  cursor: pointer;
  font-family: Poppins;
  font-weight: 300;
  font-size: 11px;
  padding-left: 8px;
  &:focus {
    outline-color: #297fcc;
  }
  ::-webkit-calendar-picker-indicator {
    background: url(${datePickerIcon}) no-repeat;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 4px 2px 0px 0px;
}
@media(max-width:1536px){
  margin-left:56.5% !important;
}
@media(max-width:1440px){
  margin-left:66.5% !important;
}
`
export const FilterGridContainer = styled.div`
  height: 2.7rem;
  position: absolute;
  top: 47px;
  display: flex;
  z-index: 1;
  align-items: center;
  width: 53.5%;
  margin-left: 40%;
`